import React, { useState, useEffect } from "react";
import Header from "./Header.js";
import Loader from "./Loader.js";
import GreyArrow from "../assets/greyLeftAr.png";
import {
  check_vaild_save,
  combiled_form_data,
  handleSuccessSession,
  handleError,
  empty_form,
  handleNumbersChange,
} from "../CommonJquery/CommonJquery.js";
import {
  server_post_data,
  get_all_productInformation,
  update_product_price_discount,
} from "../ServiceConnection/serviceconnection.js";
import { useLocation } from "react-router-dom";

function EditProduct() {
  const location = useLocation();
  const currentUrl = location.pathname.substring(1);
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [editorDataMainID, setEditorDatMainID] = useState("0");
  const [editStaffData, seteditStaffData] = useState([]);

  const handleSaveChangesdynamic = async (form_data, url_for_save) => {
    let vaild_data = check_vaild_save(form_data);

    if (vaild_data) {
      setshowLoaderAdmin(true);
      let fd_from = combiled_form_data(form_data, null);
      fd_from.append("call_id", editorDataMainID);
      await server_post_data(url_for_save, fd_from)
        .then((Response) => {
          setshowLoaderAdmin(false);
          if (Response.data.error) {
            handleError(Response.data.message);
          } else {
            master_data_get("", "", "1", "0");
            handleSuccessSession(Response.data.message, "/view_product");
            empty_form(form_data);
          }
        })
        .catch((error) => {
          console.log(error);
          setshowLoaderAdmin(false);
        });
    }
  };

  useEffect(() => {
    master_data_get();
  }, []);

  const master_data_get = async () => {
    const url = currentUrl;
    const parts = url.split("/");
    let call_id = "0";
    if (parts.length !== 1) {
      call_id = parts[1];
    }
    if (call_id !== "0") {
      setshowLoaderAdmin(true);
      const fd = new FormData();
      fd.append("call_id", call_id);
      fd.append("flag", "3");
      await server_post_data(get_all_productInformation, fd)
        .then((Response) => {
          if (Response.data.error) {
            handleError(Response.data.message);
          } else {
            if (Response.data.message.data_productInformation.length > 0) {
              setEditorDatMainID(
                Response.data.message.data_productInformation[0].primary_id
              );
              seteditStaffData(
                Response.data.message.data_productInformation[0]
              );
            }
          }
          setshowLoaderAdmin(false);
        })
        .catch((error) => {
          handleError("network");
          setshowLoaderAdmin(false);
        });
    }
  };
  console.log(editStaffData.product_price);
  console.log(editStaffData.product_disocunt_per);
  return (
    <div className="dashboard">
      {showLoaderAdmin && <Loader />}

      <div className="dashboard_container">
        <div className="page_content">
          <div className="page_content_container">
            <Header />
            <div className="pageCntn_head container-lg">
              <div className="pageNameDiv">
                <p>
                  <p>{currentUrl ? "Edit Product" : "Add Promocode"}</p>
                </p>
                <img src={GreyArrow} alt="Shopup Admin" />
              </div>
            </div>

            <div className="page_body">
              <div className="create_staffRights container-lg">
                <form className="createRightsForm" id="createRightsForm">
                  <div className="row m-0">
                    <div className="personalForm">
                      <div className="staffForm_container">
                        <div className="row m-0">
                          <div className={`col-md-3`}>
                            <div className="inpContainer">
                              <label className="no_prsnl_id">
                                Product Discount
                                <span className="colorred">*</span>
                              </label>

                              <div>
                                <input
                                  type="text"
                                  id="discount_amount"
                                  name="product_disocunt_per"
                                  maxLength={2}
                                  onInput={handleNumbersChange}
                                  className={`  trio_mandatory  input_field_customPadding form-control `}
                                  placeholder={`Product Discount %`}
                                  defaultValue={
                                    editStaffData.product_disocunt_per
                                  }
                                />

                                <span className="condition_error"></span>
                              </div>
                            </div>
                          </div>

                          <div className={`col-md-3`}>
                            <div className="inpContainer">
                              <label className="no_prsnl_id">
                                Product Amount
                                <span className="colorred">*</span>
                              </label>

                              <div>
                                <input
                                  type="text"
                                  id="min_amount"
                                  name="product_price"
                                  maxLength={10}
                                  onInput={handleNumbersChange}
                                  className={`  trio_mandatory  input_field_customPadding form-control`}
                                  placeholder="Product Amount"
                                  defaultValue={editStaffData.product_price}
                                />

                                <span className="condition_error"></span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="createRightBtnDiv">
                          <button
                            className="btn btn-primary Create_Right_Btn btnSave"
                            type="button"
                            onClick={() =>
                              handleSaveChangesdynamic(
                                "createRightsForm",
                                update_product_price_discount
                              )
                            }
                          >
                            update
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditProduct;
