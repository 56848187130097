import React, { useState, useEffect } from "react";
import Loader from "./Loader.js";
import { options_search_order } from "../CommonJquery/WebsiteText.js";
import {
  server_post_data,
  get_all_business_data,
} from "../ServiceConnection/serviceconnection.js";
import { handleError } from "../CommonJquery/CommonJquery.js";
function MobileApk() {
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  // Set initial state using useState hook
  const [filteredData, setfilteredData] = useState([]);
  const [IOSLINK, setIOSLINK] = useState([]);
  const [ANDROIDLINK, setANDROIDLINK] = useState([]);

  useEffect(() => {
    master_data_get();
  }, []);

  const master_data_get = async () => {
    setshowLoaderAdmin(true);
    await server_post_data(get_all_business_data, null)
      .then((Response) => {
        console.log(Response);
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          setfilteredData(Response.data.message.business_data[0]);
          setANDROIDLINK(Response.data.message.android_link);
          setIOSLINK(Response.data.message.ios_link);
        }

        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        console.log(error);
        handleError("network");
        setshowLoaderAdmin(false);
      });
  };

  return (
    <div className="dashboard">
      {showLoaderAdmin && <Loader />}

      <div className="dashboard_container">
        <div className="page_content">
          <div className="page_content_container">
            <div className="page_body">
              <div className="viewStaff">
                <div className="viewGuest_table ">
                  <div className="viewGuest_table_container ">
                    <div className="row m-0">
                      <div className="col-md-12 ">
                        <table className="table">
                          <tbody className="tboday">
                            <React.Fragment>
                              <tr className="tableRow tbodyStyle">
                                <td className="tabledata">
                                  <div className="guest_incenter shadowOnlyBottom">
                                    Android Apk
                                  </div>
                                </td>

                                <td className="tabledata">
                                  <div className="guest_incenter shadowOnlyBottom">
                                    <a
                                      href={
                                        ANDROIDLINK +
                                        filteredData.android_apk_link
                                      }
                                      download
                                    >
                                      <button
                                        type="button"
                                        className="markVip Mark_Vip vipMarked width132"
                                      >
                                        <p>Download App</p>
                                      </button>
                                    </a>
                                  </div>
                                </td>
                              </tr>
                              <tr
                                key={`spacer-`}
                                style={{ height: "1rem" }}
                              ></tr>
                              <tr className="tableRow  tbodyStyle">
                                <td className="tabledata">
                                  <div className="guest_incenter shadowOnlyBottom">
                                    IOS Apk
                                  </div>
                                </td>

                                <td className="tabledata">
                                  <div className="guest_incenter shadowOnlyBottom">
                                    <a
                                      href={IOSLINK + filteredData.ios_apk_link}
                                      download
                                    >
                                      <button
                                        type="button"
                                        className="markVip Mark_Vip vipMarked width132"
                                      >
                                        <p>Download App</p>
                                      </button>
                                    </a>
                                  </div>
                                </td>
                              </tr>
                              <tr
                                key={`spacer-`}
                                style={{ height: "1rem" }}
                              ></tr>
                              <tr className="tableRow  tbodyStyle">
                                <td className="tabledata">
                                  <div className="guest_incenter shadowOnlyBottom">
                                    Vendor Panel Apk
                                  </div>
                                </td>

                                <td className="tabledata">
                                  <div className="guest_incenter shadowOnlyBottom">
                                    <a
                                      href={
                                        ANDROIDLINK +
                                        filteredData.vendor_apk_link
                                      }
                                      download
                                    >
                                      <button
                                        type="button"
                                        className="markVip Mark_Vip vipMarked width132"
                                      >
                                        <p>Download App</p>
                                      </button>
                                    </a>
                                  </div>
                                </td>
                              </tr>
                              <tr
                                key={`spacer-`}
                                style={{ height: "1rem" }}
                              ></tr>
                            </React.Fragment>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MobileApk;
