import React, { useState, useEffect } from "react";
import Loader from "./Loader.js";
import FolderImg from "../assets/Folder-pana.png";
import {
  check_vaild_save,
  combiled_form_data,
  handleError,
  empty_form,
  handleSuccess,
} from "../CommonJquery/CommonJquery.js";
import {
  server_post_data,
  upload_app_logo_website,
  get_all_business_data,
  APL_LINK,
} from "../ServiceConnection/serviceconnection.js";

function AppLogo() {
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [editorDataMainID, setEditorDatMainID] = useState("0");
  const [editOldImageData, seteditOldImageData] = useState([]);
  const [dynaicimage, setDynaicimage] = useState(null);
  const handleFileChangedynamic = (keyname) => (event) => {
    const file = event.target.files[0];

    let new_file_name = keyname + "_show";
    if (!file) {
      return;
    }

    if (file && file.type.startsWith("image/")) {
      // Validate file size
      if (file.size < 200 * 1024) {
        // 200KB in bytes
        alert("File size is below the minimum limit (200KB).");
        return;
      }

      if (file.size > 500 * 1024) {
        // 500KB in bytes
        alert("File size exceeds the maximum limit (500KB).");
        return;
      }

      const reader = new FileReader();

      reader.onload = () => {
        const img = new Image();
        img.src = reader.result;

        setDynaicimage((prevImages) => ({
          ...prevImages,
          [keyname]: file,
          [new_file_name]: reader.result,
        }));
      };

      reader.readAsDataURL(file);
    } else {
      alert("Please select a valid image file.");
      event.target.value = ""; // Clear the file input
    }
  };
  const handleSaveChangesdynamic = async (form_data, url_for_save) => {
    let vaild_data = check_vaild_save(form_data);

    if (vaild_data) {
      setshowLoaderAdmin(true);
      let fd_from = combiled_form_data(form_data, dynaicimage);
      console.log(fd_from);
      fd_from.append("main_id", editorDataMainID);
      fd_from.append("old_image_link", editOldImageData);
      await server_post_data(url_for_save, fd_from)
        .then((Response) => {
          setshowLoaderAdmin(false);
          if (Response.data.error) {
            handleError(Response.data.message);
          } else {
            handleSuccess(Response.data.message);
            empty_form(form_data);
          }
        })
        .catch((error) => {
          setshowLoaderAdmin(false);
        });
    }
  };

  useEffect(() => {
    master_data_get();
  }, []);

  const master_data_get = async () => {
    setshowLoaderAdmin(true);
    await server_post_data(get_all_business_data, null)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          if (Response.data.message.business_data.length > 0) {
            setEditorDatMainID(
              Response.data.message.business_data[0].primary_id
            );
            seteditOldImageData(
              Response.data.message.business_data[0].application_logo
            );
            setDynaicimage({
              doctor_image_show:
                APL_LINK +
                Response.data.message.data_imagelink +
                Response.data.message.business_data[0].application_logo,
            });
          }
        }
        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        handleError("network");
        setshowLoaderAdmin(false);
      });
  };

  return (
    <div className="dashboard">
      {showLoaderAdmin && <Loader />}

      <div className="dashboard_container">
        <div className="page_content">
          <div className="page_content_container">
            <div className="page_body">
              <div className="create_staffRights container-lg">
                <form className="createRightsForm" id="createRightsForm">
                  <div className="row m-0">
                    <div className="col-xl-8 col-lg-10 ">
                      <div className="personalForm">
                        <div className="staffForm_container">
                          <div className="row m-0">
                            <div className={`col-md-8`}>
                              <div className="inpContainer d-grid">
                                <label className="no_prsnl_id">
                                  Image
                                  <span className="colorred imageSizeText">
                                    Size 600*600 max (max. size 500 KB)
                                  </span>
                                </label>
                                <div>
                                  <input
                                    type="file"
                                    name="doctor_image"
                                    onChange={handleFileChangedynamic(
                                      "doctor_image"
                                    )}
                                    className={`${
                                      dynaicimage &&
                                      dynaicimage.doctor_image_show
                                        ? ""
                                        : "trio_mandatory"
                                    } form-control`}
                                    accept=".jpg,.jpeg,.png"
                                    hidden
                                    id="imgInput"
                                  />
                                  <label
                                    className="imgInpBtn"
                                    htmlFor="imgInput"
                                  >
                                    {dynaicimage
                                      ? "Change Image"
                                      : " Choose Image"}
                                  </label>
                                  <span className="condition_error"></span>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6 ">
                              {dynaicimage && dynaicimage.doctor_image_show ? (
                                <img
                                  src={dynaicimage.doctor_image_show}
                                  alt="Shopup Admin"
                                  className="image_show101"
                                />
                              ) : (
                                <img src={FolderImg} alt="Shopup Admin" />
                              )}
                            </div>
                          </div>
                          <div className="createRightBtnDiv">
                            <button
                              className="btn btn-primary Create_Right_Btn btnSave"
                              type="button"
                              onClick={() =>
                                handleSaveChangesdynamic(
                                  "createRightsForm",
                                  upload_app_logo_website
                                )
                              }
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AppLogo;
