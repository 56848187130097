import React, { useEffect } from "react";
import "./App.css";
import "./Components/Css/AddStaff.css";
import "./Components/Css/Analytics.css";
import "./Components/Css/Calender.css";
import "./Components/Css/Dashboard.css";
import "./Components/Css/Header.css";
import "./Components/Css/HelpAndSupport.css";
import "./Components/Css/Loading.css";
import "./Components/Css/Login.css";
import "./Components/Css/Reports.css";
import "./Components/Css/Reservations.css";
import "./Components/Css/RestoSetup.css";
import "./Components/Css/Settings.css";
import "./Components/Css/SideBar.css";
import "./Components/Css/StaffRights.css";
import "./Components/Css/TableSetup.css";
import "./Components/Css/WebsiteMngmt.css";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import Login from "./Components/Login";
import GuestMngmt from "./Components/GuestMngmt";
import Analytics from "./Components/Analytics";
import Manuals from "./Components/Manuals";
import Report from "./Components/Reports";
import BannerMngmt from "./Components/BannerMngmt";
import Add_Banner from "./Components/Add_Banner";
import Add_Product from "./Components/Add_Product";
import ProductMngmt from "./Components/ProductMngmt";
import PrmocodeMngmt from "./Components/PrmocodeMngmt";
import Add_Promocode from "./Components/Add_Promocode";
import NotificationMngmt from "./Components/NotificationMngmt";
import OrderMngmt from "./Components/OrderMngmt";
import Setting from "./Components/Setting";
import CustomerHelp from "./Components/CustomerHelp";
import CategoryMngmt from "./Components/CategoryMngmt";
import EditProduct from "./Components/EditProduct";
import CreateNewTicket from "./Components/CreateTicket";
import ReplyToTicket from "./Components/ReplyToTickect";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function App() {
  useEffect(() => {
    const inputElements = document.getElementsByTagName("input");
    Array.from(inputElements).forEach((input) => {
      input.setAttribute("autocomplete", "off");
    });
  }, [Array]);

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          {/* Working Screen */}
          <Route path="/view_customer" element={<GuestMngmt />} />
          <Route path="/view_banner" element={<BannerMngmt />} />
          <Route path="/add_banner" element={<Add_Banner />} />
          <Route path="/edit_banner/:id" element={<Add_Banner />} />
          <Route path="/view_promocode" element={<PrmocodeMngmt />} />
          <Route path="/add_promocode" element={<Add_Promocode />} />
          <Route path="/edit_promocode/:id" element={<Add_Promocode />} />
          <Route path="/view_notification" element={<NotificationMngmt />} />
          <Route path="/view_orders" element={<OrderMngmt />} />
          <Route path="/view_product" element={<ProductMngmt />} />
          <Route path="/add_product" element={<Add_Product />} />
          <Route path="/edit_product/:id" element={<EditProduct />} />
          <Route path="/Customer_Help" element={<CustomerHelp />} />
          <Route path="/Setting" element={<Setting />} />
          <Route path="/View_Reports" element={<Report />} />
          <Route path="/Manuals" element={<Manuals />} />
          <Route path="/Dashboard" element={<Analytics />} />
          <Route path="/View_Category_list" element={<CategoryMngmt />} />
          <Route path="/" element={<Login />} />
          <Route path="/Manuals/Create_Ticket" element={<CreateNewTicket />} />
          <Route path="/Manuals/Reply_Ticket/:ticketId" element={<ReplyToTicket />} />
        </Routes>
      </BrowserRouter>

      <ToastContainer />
    </div>
  );
}

export default App;
