import React, { useState, useEffect } from "react";
import { BlogWebsite_text } from "../CommonJquery/WebsiteText";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {
  server_post_data,
  get_terms_and_privacy_data,
  update_terms_and_privacy_data,
} from "../ServiceConnection/serviceconnection.js";
import { handleError, handleSuccess } from "../CommonJquery/CommonJquery";
import Loader from "./Loader.js";
const PrivacyPolicy = () => {
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [editStaffDataDetails, seteditStaffDataDetails] = useState([]);
  const [error_show, seterror_show] = useState("");

  useEffect(() => {
    master_data_get();
  }, []);

  const master_data_get = async () => {
    await server_post_data(get_terms_and_privacy_data, null)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          seteditStaffDataDetails(Response.data.message.privacy_policy);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const master_data_update = async (url_for_save) => {
    if (editStaffDataDetails.length > 0) {
      setshowLoaderAdmin(true);
      const fd = new FormData();
      fd.append("flag", 2);
      fd.append("data", editStaffDataDetails);
      await server_post_data(url_for_save, fd)
        .then((Response) => {
          if (Response.data.error) {
            handleError(Response.data.message);
          } else {
            handleSuccess(Response.data.message);
            master_data_get("");
          }

          setshowLoaderAdmin(false);
        })
        .catch((error) => {
          console.log(error);
          handleError("network");
          setshowLoaderAdmin(false);
        });
    } else {
      seterror_show("Please add Description");
    }
  };

  const handleEditorChange = (event, editor) => {
    seteditStaffDataDetails(editor.getData());
  };

  return (
    <div>
      {showLoaderAdmin && <Loader />}
      <div>
        <div className={`col-md-12`}>
          <div className="inpContainer mt-2">
            <label className="no_prsnl_id">
              Privacy And Policy
              <span className="colorred">*</span>
            </label>
            <div>
              <CKEditor
                editor={ClassicEditor}
                data={editStaffDataDetails || ""}
                onChange={handleEditorChange}
              />
            </div>
            <span className="condition_error">{error_show}</span>
          </div>
          <div className="col-md-12">
            <div className="addBlogBtnDiv">
              <button
                type="button"
                onClick={() =>
                  master_data_update(update_terms_and_privacy_data)
                }
                className="btn btn-secondary mt-3 save-cap-btn"
              >
                {BlogWebsite_text.save_txt}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
