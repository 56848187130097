import React, { useState, useEffect } from "react";
import Header from "./Header.js";
import Loader from "./Loader.js";
import GreyArrow from "../assets/greyLeftAr.png";
import FilterIcon from "../assets/filterIcon.png";
import GuestIcon from "../assets/guestIcon.png";
import { Modal, Button } from "react-bootstrap";
import { PromocodeManagementPageText } from "../CommonJquery/WebsiteText.js";
import {
  server_post_data,
  get_promocode_data,
  delete_master_all,
} from "../ServiceConnection/serviceconnection.js";
import {
  handleError,
  computeTodayDate,
  handleIaphabetnumberChange,
  computeplussevendays,
  formatDateStringdot,
} from "../CommonJquery/CommonJquery.js";
import { Link } from "react-router-dom";
function PrmocodeMngmt() {
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [editStaffData, seteditStaffData] = useState([]);
  // Set initial state using useState hook
  const [startDate, setStartDate] = useState(computeplussevendays());
  const [endDate, setEndDate] = useState(computeTodayDate());
  const [SelectedData, setSelectedData] = useState([]);
  const [searchfilter, setSearchFilter] = useState("");
  const [filteredData, setfilteredData] = useState([]);
  const [flagData, setflagData] = useState("1");
  useEffect(() => {
    const flag = "1";
    let call_id = "0";
    master_data_get(startDate, endDate, flag, call_id);
  }, []);

  const search_data = () => {
    const flag = "2";
    const call_id = "0";
    master_data_get(startDate, endDate, flag, call_id);
  };

  const master_data_get = async (start_date, end_date, flag, call_id) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    setflagData(flag);
    fd.append("start_date", start_date);
    fd.append("end_date", end_date);
    fd.append("flag", flag);
    fd.append("call_id", call_id);
    await server_post_data(get_promocode_data, fd)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          Response.data.message.data_businesspromocode.forEach((subObj) => {
            if (subObj.promocode_type === 0) {
              subObj.banner_positiontest = "Discount";
              subObj.discount_amount = subObj.discount_amount + "%";
            } else {
              subObj.banner_positiontest = "Amount";
              subObj.discount_amount =
                Response.data.message.rupees_left +
                " " +
                subObj.discount_amount +
                " " +
                Response.data.message.rupess_right;
            }
          });

          seteditStaffData(Response.data.message.data_businesspromocode);
          setfilteredData(Response.data.message.data_businesspromocode);
        }

        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        handleError("network");
        setshowLoaderAdmin(false);
      });
  };

  const master_data_action_update = async (call_id, for_status_final) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();

    fd.append("id_for_delete", call_id);
    fd.append("flag_for", "2");
    fd.append("for_status_final", for_status_final);
    await server_post_data(delete_master_all, fd)
      .then((Response) => {
        setshowLoaderAdmin(false);
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          closeModal();
          master_data_get(startDate, endDate, flagData, "0");
        }
      })
      .catch((error) => {
        console.log(error)
        handleError("network");
        setshowLoaderAdmin(false);
      });
  };

  const handleSearch = (event) => {
    const searchValue = event.target.value;
    setSearchFilter(searchValue);

    // Filter table data based on search value
    const filteredDatashow = editStaffData.filter((row) => {
      return Object.values(row).some((value) => {
        if (value !== null && value !== undefined) {
          // Add null check here
          return (
            typeof value === "string"
              ? value.toLowerCase()
              : value.toString().toLowerCase()
          ).includes(searchValue.toLowerCase());
        }
        return false;
      });
    });

    setfilteredData(filteredDatashow);
  };
  const [showModal, setShowModal] = useState(false);
  const [selectedGuestIndex, setSelectedGuestIndex] = useState(null);

  const openModal = (guestName, index) => {
    setSelectedData(guestName);
    setShowModal(true);
    setSelectedGuestIndex(index);
  };

  const confirmVIP = (index) => {
    master_data_action_update(SelectedData.primary_id, "d");
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div className="dashboard">
      {showLoaderAdmin && <Loader />}

      <div className="dashboard_container">
        <div className="page_content">
          <div className="page_content_container">
            <Header />
            <div className="pageCntn_head">
              <div className="pageNameDiv">
                <p>{PromocodeManagementPageText.Guest_Management}</p>
                <img src={GreyArrow} alt="Shopup Admin" />
              </div>
              <div className="col-xl-2 col-sm-4 bottomAlgin">
                <Link to={`/add_promocode`}>
                  <button type="button" className="btnAddStaff add_search">
                    Add Promocode
                  </button>
                </Link>
              </div>
            </div>

            <div className="page_body">
              <div className="viewStaff">
                <div className="viewStaff_head">
                  <div className="row m-0">
                    <div className="col-lg-2 col-sm-4 bottomAlgin">
                      <label className="labelView">
                        {PromocodeManagementPageText.Start_Date}
                      </label>
                      <div className="person__calenderFrame_image image_icon_position1 ">
                        <input
                          id="startDate"
                          type="date"
                          placeholder={PromocodeManagementPageText.D_O_B}
                          className="form-control  input_field_custom4"
                          defaultValue={startDate}
                          onChange={(e) => setStartDate(e.target.value)}
                          max={computeTodayDate()}
                        />
                      </div>
                    </div>
                    <div className="col-lg-2 col-sm-4 bottomAlgin">
                      <label className="labelView">
                        {PromocodeManagementPageText.End_Date}
                      </label>
                      <div className="person__calenderFrame_image image_icon_position1 ">
                        <input
                          id="endDate"
                          type="date"
                          placeholder={PromocodeManagementPageText.D_O_B}
                          className="form-control  input_field_custom4"
                          defaultValue={endDate}
                          onChange={(e) => setEndDate(e.target.value)}
                          max={computeTodayDate()}
                        />
                      </div>
                    </div>

                    <div className="col-lg-2 col-sm-4 bottomAlgin">
                      <button
                        type="button"
                        className="btnSearchNew w-100"
                        onClick={() => search_data()}
                      >
                        {PromocodeManagementPageText.Search_text}
                      </button>
                    </div>

                    <div
                      className="col-xl-3 col-md-4 bottomAlgin"
                      style={{ marginLeft: "auto", marginRight: "0" }}
                    >
                      <div className="inputDiv2">
                        <label className="labelPointer" htmlFor="selectFilter">
                          <img src={FilterIcon} alt="Shopup Admin" />
                        </label>
                        <input
                          type="text"
                          placeholder={PromocodeManagementPageText.Guest_Filter}
                          value={searchfilter}
                          onInput={handleIaphabetnumberChange}
                          onChange={handleSearch}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="viewGuest_table ">
                  <div className="viewGuest_table_container ">
                    <div className="row m-0">
                      <div className="col-md-12">
                        <table className="table">
                          <thead>
                            <tr>
                              <th scope="col">
                                <div className="theadStyle imgThead  ">
                                  <img src={GuestIcon} alt="Shopup Admin" />
                                  <span>
                                    {PromocodeManagementPageText.Guest_Image}
                                  </span>
                                </div>
                              </th>

                              <th scope="col">
                                <div className="theadStyle">
                                  {PromocodeManagementPageText.Position_dada}
                                </div>
                              </th>
                              <th scope="col">
                                <div className="theadStyle">
                                  {PromocodeManagementPageText.Discount_amount}
                                </div>
                              </th>
                              <th scope="col">
                                <div className="theadStyle">
                                  {PromocodeManagementPageText.From_to}
                                </div>
                              </th>
                              <th scope="col">
                                <div className="theadStyle">{PromocodeManagementPageText.Action_items}</div>
                              </th>
                            </tr>
                          </thead>
                          <tbody className="tboday">
                            {filteredData.map((option, index) => (
                              <React.Fragment key={index}>
                                <tr className="tableRow tbodyStyle">
                                  <td className="th1 tabledata">
                                    <div className="guest_incenter borderLeftRadius">
                                      {option.promo_code}
                                    </div>
                                  </td>
                                  <td className="tabledata">
                                    <div className="guest_incenter shadowOnlyBottom">
                                      {option.banner_positiontest}
                                    </div>
                                  </td>
                                  <td className="tabledata">
                                    <div className="guest_incenter shadowOnlyBottom">
                                      {option.discount_amount}
                                    </div>
                                  </td>
                                  <td className="tabledata">
                                    <div className="guest_incenter shadowOnlyBottom">
                                      {formatDateStringdot(option.avail_from)}-
                                      {formatDateStringdot(option.expiration)}
                                    </div>
                                  </td>

                                  <td className="th2 tabledata">
                                    <div className="actionsAlign">
                                      <div className="guest_incenterActions borderRightRadius">
                                        <Link
                                          to={`/edit_promocode/${option.primary_id}`}
                                        >
                                          <button
                                            type="button"
                                            className={`markVip Mark_Vip`}
                                            onClick={() =>
                                              openModal(option, index)
                                            }
                                          >
                                            <p>Edit</p>
                                          </button>
                                        </Link>
                                      </div>
                                      <div className="guest_incenterActions borderRightRadius">
                                        <button
                                          type="button"
                                          className={`markVip Mark_Vip`}
                                          onClick={() =>
                                            openModal(option, index)
                                          }
                                        >
                                          <p>Delete</p>
                                        </button>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                                <tr
                                  key={`spacer-${index}`}
                                  style={{ height: "1rem" }}
                                ></tr>
                              </React.Fragment>
                            ))}
                            {filteredData.length === 0 && (
                              <tr>
                                <td colSpan={8} className="text_align_center border_1px">
                                  No Results Found
                                </td>{" "}
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showModal} onHide={closeModal} centered backdrop="static">
        <Modal.Body className="modal_body">
          <div className="success_img d-flex justify-content-center"></div>

          <p>Are you sure you want to this Promocode?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="logoutYesBtn"
            onClick={() => confirmVIP(selectedGuestIndex)}
          >
            Yes
          </Button>
          <Button className="logoutNoBtn" onClick={closeModal}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default PrmocodeMngmt;
