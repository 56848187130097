import React, { useState, useEffect } from "react";
import Header from "./Header";
import Loader from "./Loader.js";
import GreyArrow from "../assets/greyLeftAr.png";
import FilterIcon from "../assets/filterIcon.png";
import Search from "../assets/search.png";
import { Modal, Button } from "react-bootstrap";
import { GuestManagementPageText } from "./../CommonJquery/WebsiteText";
import {
  server_post_data,
  get_all_customer_for_help,
  update_customer_help_status,
} from "../ServiceConnection/serviceconnection.js";
import {
  handleError,
  computeTodayDate,
  handleIaphabetnumberChange,
  computeplussevendays,
  handleSuccess,
} from "../CommonJquery/CommonJquery";
function CustomerHelp() {
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [editStaffData, seteditStaffData] = useState([]);
  // Set initial state using useState hook
  const [startDate, setStartDate] = useState(computeplussevendays());
  const [endDate, setEndDate] = useState(computeTodayDate());
  const [SelectedData, setSelectedData] = useState([]);
  const [searchfilter, setSearchFilter] = useState("");
  const [filteredData, setfilteredData] = useState([]);
  const [flagData, setflagData] = useState("1");
  const [showModal, setShowModal] = useState(false);
  const [selectedGuestIndex, setSelectedGuestIndex] = useState(null);

  useEffect(() => {
    const flag = "1";
    let call_id = "0";
    master_data_get(startDate, endDate, flag, call_id);
  }, []);

  const search_data = () => {
    const flag = "2";
    const call_id = "0";
    master_data_get(startDate, endDate, flag, call_id);
  };

  const master_data_get = async (start_date, end_date, flag, call_id) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    setflagData(flag);
    fd.append("start_date", start_date);
    fd.append("end_date", end_date);
    fd.append("flag", flag);
    fd.append("call_id", call_id);
    await server_post_data(get_all_customer_for_help, fd)
      .then((Response) => {
        console.log(Response.data.message.data_customer_help);
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          Response.data.message.data_customer_help.forEach((subObj) => {
            if (subObj.active_status === 0) {
              subObj.vipmarkdata = "Active";
            } else {
              subObj.vipmarkdata = "Deactive";
            }
          });
          seteditStaffData(Response.data.message.data_customer_help);
          setfilteredData(Response.data.message.data_customer_help);
        }

        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        handleError("network");
        setshowLoaderAdmin(false);
      });
  };
  const master_data_action_update = async (call_id, reject_text) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("id_for_delete", call_id);
    fd.append("remark", reject_text);
    console.log(call_id, reject_text);
    await server_post_data(update_customer_help_status, fd)
      .then((Response) => {
        console.log(Response);
        setshowLoaderAdmin(false);
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          handleSuccess(Response.data.message);
          closeModal();
          master_data_get(startDate, endDate, "1", "0");
        }
      })
      .catch((error) => {
        handleError("network");
        setshowLoaderAdmin(false);
      });
  };

  const handleSearch = (event) => {
    const searchValue = event.target.value;
    setSearchFilter(searchValue);

    // Filter table data based on search value
    const filteredDatashow = editStaffData.filter((row) => {
      return Object.values(row).some((value) => {
        if (value !== null && value !== undefined) {
          // Add null check here
          return (
            typeof value === "string"
              ? value.toLowerCase()
              : value.toString().toLowerCase()
          ).includes(searchValue.toLowerCase());
        }
        return false;
      });
    });

    setfilteredData(filteredDatashow);
  };

  const openModal = (guestName, index) => {
    let tesr_show = " Not ";
    if (guestName.active_status === 0) {
      tesr_show = "";
    }
    setSelectedData(guestName);
    setShowModal(true);
    setSelectedGuestIndex(index);
  };

  const confirmVIP = (index) => {
    let reject_text = document.getElementById("disc_msg").value;
    let allow_access_data = "0";
    if (SelectedData.active_status === 0) {
      allow_access_data = "1";
    }
    master_data_action_update(
      SelectedData.primary_id,
      reject_text,
      allow_access_data
    );
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div className="dashboard">
      {showLoaderAdmin && <Loader />}

      <div className="dashboard_container">
        <div className="page_content">
          <div className="page_content_container">
            <Header />
            <div className="pageCntn_head">
              <div className="pageNameDiv">
                <p>Feedback</p>
                <img src={GreyArrow} alt="Shopup Admin" />
              </div>
            </div>

            <div className="page_body">
              <div className="viewStaff">
                <div className="viewStaff_head">
                  <div className="row m-0">
                    <div className="col-lg-2 col-sm-3 bottomAlgin">
                      <label className="labelView">
                        {GuestManagementPageText.Start_Date}
                      </label>
                      <div className="person__calenderFrame_image image_icon_position1 ">
                        <input
                          id="startDate"
                          type="date"
                          placeholder={GuestManagementPageText.D_O_B}
                          className="form-control  input_field_custom4"
                          defaultValue={startDate}
                          onChange={(e) => setStartDate(e.target.value)}
                          max={computeTodayDate()}
                        />
                      </div>
                    </div>
                    <div className="col-lg-2 col-sm-3 bottomAlgin">
                      <label className="labelView">
                        {GuestManagementPageText.End_Date}
                      </label>
                      <div className="person__calenderFrame_image image_icon_position1 ">
                        <input
                          id="endDate"
                          type="date"
                          placeholder={GuestManagementPageText.D_O_B}
                          className="form-control  input_field_custom4"
                          defaultValue={endDate}
                          onChange={(e) => setEndDate(e.target.value)}
                          max={computeTodayDate()}
                        />
                      </div>
                    </div>

                    <div className="col-lg-2 col-sm-1 bottomAlgin">
                      <button
                        type="button"
                        className="btnSearchNew"
                        onClick={() => search_data()}
                      >
                        {GuestManagementPageText.Search_text}
                      </button>
                    </div>

                    <div
                      className="col-xl-3 col-md-4 bottomAlgin"
                      style={{ marginLeft: "auto", marginRight: "0" }}
                    >
                      <div className="inputDiv2">
                        <label className="labelPointer" htmlFor="selectFilter">
                          <img src={FilterIcon} alt="Shopup Admin" />
                        </label>
                        <input
                          type="text"
                          placeholder={GuestManagementPageText.Guest_Filter}
                          value={searchfilter}
                          onInput={handleIaphabetnumberChange}
                          onChange={handleSearch}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="viewGuest_table ">
                  <div className="viewGuest_table_container ">
                    <div className="row m-0">
                      <div className="col-md-12">
                        <table className="table">
                          <thead>
                            <tr>
                              <th scope="col">
                                <h5 className="theadStyle font-bold	">
                                  S.No.
                                </h5>
                              </th>
                              <th scope="col">
                                <h5 className="theadStyle font-bold	">
                                  Issue
                                </h5>
                              </th>
                              <th scope="col">
                                <h5 className="theadStyle font-black	">
                                  Comment
                                </h5>
                              </th>
                              <th scope="col">
                                <h5 className="theadStyle font-black	">
                                  Remarks
                                </h5>
                              </th>
                              <th scope="col"></th>
                            </tr>
                          </thead>
                          <tbody className="tboday">
                            {filteredData.map((option, index) => (
                              <React.Fragment key={index}>
                                <tr className="tableRow tbodyStyle">
                                  <td className="tabledata">
                                    <div className="guest_incenter shadowOnlyBottom">
                                      {index +1}
                                    </div>
                                  </td>
                                  <td className="tabledata">
                                    <div className="guest_incenter shadowOnlyBottom">
                                      {option.title}
                                    </div>
                                  </td>
                                  <td className="tabledata">
                                    <div className="guest_incenter shadowOnlyBottom">
                                      {option.description}
                                    </div>
                                  </td>
                                  <td className="th2 tabledata">
                                    <div className="guest_incenterActions borderRightRadius">
                                      <button
                                        type="button"
                                        className={`markVip Mark_Vip m-0`}
                                        onClick={() => openModal(option, index)}
                                        style={{ width: "110px" }}
                                      >
                                        {option.help_remark !== "" ? (
                                          <p>Read Remark</p>
                                        ) : (
                                          <p>Reply</p>
                                        )}
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                                <tr
                                  key={`spacer-${index}`}
                                  style={{ height: "1rem" }}
                                ></tr>
                              </React.Fragment>
                            ))}
                            {filteredData.length === 0 && (
                              <tr>
                                <td
                                  colSpan={8}
                                  className="text_align_center border_1px"
                                >
                                  No Results Found
                                </td>{" "}
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showModal} onHide={closeModal} centered backdrop="static">
        <Modal.Body className="modal_body">
          {" "}
          <form className="createRightsForm" id="createRightsForm">
            <div className="success_img  justify-content-center">
              <div className={`row`}>
                <div className="inpContainer">
                  <label className="no_prsnl_id d-flex">
                    <p>Remark</p>
                    <span className="colorred">*</span>
                  </label>
                  {SelectedData.help_remark !== "" ? (
                    <>
                      <br></br>
                      <span>{SelectedData.help_remark || ""}</span>
                    </>
                  ) : (
                    <div>
                      <input
                        type="text"
                        id="disc_msg"
                        name="disc_msg"
                        maxLength={100}
                        onInput={handleIaphabetnumberChange}
                        className={`  trio_mandatory  input_field_customPadding form-control`}
                        placeholder="Enter Remark For this"
                        defaultValue={SelectedData.help_remark || ""}
                      />
                      <span className="condition_error"></span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          {SelectedData.help_remark === "" && (
            <Button
              className="logoutYesBtn"
              onClick={() => confirmVIP(selectedGuestIndex)}
            >
              Save
            </Button>
          )}
          <Button className="logoutNoBtn" onClick={closeModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default CustomerHelp;
