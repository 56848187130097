import React from "react";
import OpenMenu from "../assets/menu_open.png";
import Guest from "../assets/sideGuestBlack.svg";
import Analytics from "../assets/sideAnalytisBlack.svg";
import Report from "../assets/sideReportBlack.svg";
import Dashboard from "../assets/sideDashblack.svg";
import DashboardB from "../assets/sideDashBlue.svg";
import HelpSupport from "../assets/sideHelpBlack.svg";
import GuestOrng from "../assets/sideGuestOrng.svg";
import ReportOrng from "../assets/sideReportOrng.svg";
import HelpSupportOrng from "../assets/sideHelpOrng.svg";
import Setting from "../assets/sideSettingBlack.svg";
import SettingB from "../assets/sideSettingsBlue.svg";
import Feedback from "../assets/sideFeedBlack.svg";
import FeedbackB from "../assets/sideFeedBlue.svg";
import Notification from "../assets/sideNotiBlack.svg";
import NotificationB from "../assets/sideNotiBlue.svg";
import Promocode from "../assets/sidePromoBlack.svg";
import PromocodeB from "../assets/sidePromoBlue.svg";
import Banner from "../assets/sideBannedBlack.svg";
import BannerB from "../assets/sideBannerBlue.svg";
import Product from "../assets/sideProBlack.svg";
import Orders from "../assets/sideOrdersBlack.svg";
import OrdersB from "../assets/sideOrderBlue.svg";
import ProeductB from "../assets/sideCartBLue.svg";
import NewLogo from "../assets/logo_shopup.png";
import { Link, useLocation } from "react-router-dom";
function SideBar({ isSidebarOpen, toggleSidebar }) {
  const location = useLocation();

  return (
    <div className={`sidebar ${isSidebarOpen ? "OpneSidebar" : ""}`}>
      <div className="sidebar_container">
        <div className="sideHeadLogo">
          <a className="navbar-brand" href="#">
            <div className="headerLogo">
              <div className="headerLogo_contaienr">
                <img src={NewLogo} alt="Shopup Admin" />
              </div>
            </div>
          </a>
        </div>
        <div
          data-bs-toggle="tooltip"
          data-bs-placement="left"
          htmlFor="top"
          title={`Hide`}
          className="menuOpenClose"
          onClick={toggleSidebar}
          style={{
            paddingLeft: "0",
            marginLeft: "2rem",
            justifyContent: "flex-start",
          }}
        >
          <img
            style={{ transform: " rotate(0deg)" }}
            src={OpenMenu}
            alt="Shopup Admin"
          />
        </div>
        <div className="sidebar_list">
          <ul>
            <Link to="/Dashboard">
              <li>
                <div
                  className={`sidebar_list_item ${
                    location.pathname === "/Dashboard" ? "activeSideItem" : " "
                  }`}
                >
                  <img
                    src={
                      location.pathname === "/Dashboard"
                        ? DashboardB
                        : Dashboard
                    }
                    alt="Shopup Admin"
                  />
                  <p>Dashboard</p>
                </div>
              </li>
            </Link>

            <Link to="/view_orders">
              <li>
                <div
                  className={`sidebar_list_item ${
                    location.pathname === "/view_orders"
                      ? "activeSideItem"
                      : " "
                  }`}
                >
                  <img
                    src={
                      location.pathname === "/view_orders" ? OrdersB : Orders
                    }
                    alt="Shopup Admin"
                  />
                  <p>Orders</p>
                </div>
              </li>
            </Link>
            <Link to="/view_product">
              <li>
                <div
                  className={`sidebar_list_item ${
                    location.pathname === "/view_product" ||
                    location.pathname === "/add_product" ||
                    location.pathname === "/View_Category_list" ||
                    location.pathname.includes("/edit_product")
                      ? "activeSideItem"
                      : " "
                  }`}
                >
                  <img
                    src={
                      location.pathname === "/view_product" ||
                      location.pathname === "/add_product" ||
                      location.pathname === "/View_Category_list" ||
                      location.pathname.includes("/edit_product")
                        ? ProeductB
                        : Product
                    }
                    alt="Shopup Admin"
                  />
                  <p>Product</p>
                </div>
              </li>
            </Link>
            <Link to="/view_customer">
              <li>
                <div
                  className={`sidebar_list_item ${
                    location.pathname === "/view_customer"
                      ? "activeSideItem"
                      : " "
                  }`}
                >
                  <img
                    src={
                      location.pathname === "/view_customer" ? GuestOrng : Guest
                    }
                    alt="Shopup Admin"
                  />
                  <p>Customers</p>
                </div>
              </li>
            </Link>
            <Link to="/view_banner">
              <li>
                <div
                  className={`sidebar_list_item ${
                    location.pathname === "/view_banner" ||
                    location.pathname === "/add_banner" ||
                    location.pathname.includes("/edit_banner")
                      ? "activeSideItem"
                      : " "
                  }`}
                >
                  <img
                    src={
                      location.pathname === "/view_banner" ||
                      location.pathname === "/add_banner" ||
                      location.pathname.includes("/edit_banner")
                        ? BannerB
                        : Banner
                    }
                    alt="Shopup Admin"
                  />
                  <p>Banner</p>
                </div>
              </li>
            </Link>
            <Link to="/view_promocode">
              <li>
                <div
                  className={`sidebar_list_item ${
                    location.pathname === "/view_promocode" ||
                    location.pathname === "/add_promocode" ||
                    location.pathname.includes("/edit_promocode")
                      ? "activeSideItem"
                      : " "
                  }`}
                >
                  <img
                    src={
                      location.pathname === "/view_promocode" ||
                      location.pathname === "/add_promocode" ||
                      location.pathname.includes("/edit_promocode")
                        ? PromocodeB
                        : Promocode
                    }
                    alt="Shopup Admin"
                  />
                  <p>Promocode</p>
                </div>
              </li>
            </Link>

            <Link to="/view_notification">
              <li>
                <div
                  className={`sidebar_list_item ${
                    location.pathname === "/view_notification"
                      ? "activeSideItem"
                      : " "
                  }`}
                >
                  <img
                    src={
                      location.pathname === "/view_notification"
                        ? NotificationB
                        : Notification
                    }
                    alt="Shopup Admin"
                  />
                  <p>Notification App</p>
                </div>
              </li>
            </Link>
            <Link to="/view_reports">
              <li>
                <div
                  className={`sidebar_list_item ${
                    location.pathname === "/view_reports"
                      ? "activeSideItem"
                      : " "
                  }`}
                >
                  <img
                    src={
                      location.pathname === "/view_reports"
                        ? ReportOrng
                        : Report
                    }
                    alt="Shopup Admin"
                  />
                  <p>Reports</p>
                </div>
              </li>
            </Link>
            <Link to="/Customer_Help">
              <li className={`reports sidebarDropdown`}>
                <div
                  className={`sidebar_list_item ${
                    location.pathname === "/Customer_Help"
                      ? "activeSideItem"
                      : " "
                  }`}
                >
                  <img
                    src={
                      location.pathname === "/Customer_Help"
                        ? FeedbackB
                        : Feedback
                    }
                    alt="Shopup Admin"
                  />
                  <p>Feedback</p>
                </div>
              </li>
            </Link>
            <Link to="/Setting">
              <li className={`reports sidebarDropdown`}>
                <div
                  className={`sidebar_list_item ${
                    location.pathname === "/Setting" ? "activeSideItem" : " "
                  }`}
                >
                  <img
                    src={location.pathname === "/Setting" ? SettingB : Setting}
                    alt="Shopup Admin"
                  />
                  <p>Setting</p>
                </div>
              </li>
            </Link>
            <Link to="/Manuals">
              <li>
                <div
                  className={`sidebar_list_item ${
                    location.pathname === "/Manuals" ? "activeSideItem" : " "
                  }`}
                >
                  <img
                    src={
                      location.pathname === "/Help_And_Support" ||
                      location.pathname === "/FAQs" ||
                      location.pathname === "/Manuals"
                        ? HelpSupportOrng
                        : HelpSupport
                    }
                    alt="Shopup Admin"
                  />
                  <p>Help and Support</p>
                </div>
              </li>
            </Link>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default SideBar;
