import React, { useState, useEffect } from "react";
import Header from "./Header";
import Loader from "./Loader.js";
import GreyArrow from "../assets/greyLeftAr.png";
import FilterIcon from "../assets/filterIcon.png";
import Search from "../assets/search.png";
import GuestIcon from "../assets/guestIcon.png";
import { Modal, Button } from "react-bootstrap";
import {
  options_search,
  GuestManagementPageText,
} from "./../CommonJquery/WebsiteText";
import {
  server_post_data,
  get_all_guestInformation,
  update_guest_active_status,
  APL_LINK,
} from "../ServiceConnection/serviceconnection.js";
import {
  handleError,
  computeTodayDate,
  handleIaphabetnumberChange,
  handleAphabetsChange,
  handleEmailChange,
  handleNumbersChange,
  computeplussevendays,
} from "../CommonJquery/CommonJquery";
function GuestMngmt() {
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [editStaffData, seteditStaffData] = useState([]);
  // Set initial state using useState hook
  const [startDate, setStartDate] = useState(computeplussevendays());
  const [endDate, setEndDate] = useState(computeTodayDate());
  const [SelectedData, setSelectedData] = useState([]);
  const [searchfilter, setSearchFilter] = useState("");
  const [filteredData, setfilteredData] = useState([]);
  const [flagData, setflagData] = useState("1");
  const [selectedValue, setSelectedValue] = useState("Name"); // State to hold the selected value
  const [StaffImageLinkData, setsStaffImageLinkData] = useState("");
  const handleSelect = (event) => {
    setSelectedValue(event.target.value); // Update the selected value when an option is selected
  };

  useEffect(() => {
    const flag = "1";
    let call_id = "0";
    master_data_get(startDate, endDate, flag, call_id);
  }, []);

  const search_data = () => {
    const flag = "2";
    const call_id = "0";
    master_data_get(startDate, endDate, flag, call_id);
  };

  const master_data_get = async (start_date, end_date, flag, call_id) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    setflagData(flag);
    fd.append("start_date", start_date);
    fd.append("end_date", end_date);
    fd.append("flag", flag);
    fd.append("call_id", call_id);
    fd.append("search_option", selectedValue);
    fd.append("search_data", document.getElementById("search_data").value);
    await server_post_data(get_all_guestInformation, fd)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          Response.data.message.data_customerInformation.forEach((subObj) => {
            if (subObj.active_status === 1) {
              subObj.vipmarkdata = "Deactive";
            } else {
              subObj.vipmarkdata = "Active";
            }
          });
          setsStaffImageLinkData(
            APL_LINK + Response.data.message.data_imagelink
          );
          seteditStaffData(Response.data.message.data_customerInformation);
          setfilteredData(Response.data.message.data_customerInformation);
        }

        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        handleError("network");
        setshowLoaderAdmin(false);
      });
  };

  const master_data_action_update = async (call_id, for_status_final) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();

    fd.append("id_for_delete", call_id);
    fd.append("flag_for", "1");
    fd.append("for_status_final", for_status_final);
    await server_post_data(update_guest_active_status, fd)
      .then((Response) => {
        setshowLoaderAdmin(false);
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          closeModal();
          master_data_get(startDate, endDate, flagData, "0");
        }
      })
      .catch((error) => {
        handleError("network");
        setshowLoaderAdmin(false);
      });
  };

  const handleSearch = (event) => {
    const searchValue = event.target.value;
    setSearchFilter(searchValue);

    // Filter table data based on search value
    const filteredDatashow = editStaffData.filter((row) => {
      return Object.values(row).some((value) => {
        if (value !== null && value !== undefined) {
          // Add null check here
          return (
            typeof value === "string"
              ? value.toLowerCase()
              : value.toString().toLowerCase()
          ).includes(searchValue.toLowerCase());
        }
        return false;
      });
    });

    setfilteredData(filteredDatashow);
  };
  const [showModal, setShowModal] = useState(false);
  const [clickedButton, setClickedButton] = useState(null);
  const [selectedGuestIndex, setSelectedGuestIndex] = useState(null);

  const openModal = (guestName, index) => {
    let tesr_show = " Not ";
    if (guestName.active_status === 0) {
      tesr_show = "";
    }
    setSelectedData(guestName);
    setShowModal(true);
    setClickedButton(guestName.full_name + tesr_show);
    setSelectedGuestIndex(index);
  };

  const confirmVIP = (index) => {
    let allow_access_data = "0";
    if (SelectedData.active_status === 0) {
      allow_access_data = "1";
    }
    master_data_action_update(SelectedData.primary_id, allow_access_data);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div className="dashboard">
      {showLoaderAdmin && <Loader />}

      <div className="dashboard_container">
        <div className="page_content">
          <div className="page_content_container">
            <Header />
            <div className="pageCntn_head">
              <div className="pageNameDiv">
                <p>{GuestManagementPageText.Guest_Management}</p>
                <img src={GreyArrow} alt="Shopup Admin" />
              </div>
            </div>

            <div className="page_body">
              <div className="viewStaff">
                <div className="viewStaff_head">
                  <div className="row m-0">
                    <div className="col-lg-2 col-sm-4 bottomAlgin">
                      <label className="labelView">
                        {GuestManagementPageText.Start_Date}
                      </label>
                      <div className="person__calenderFrame_image image_icon_position1 ">
                        <input
                          id="startDate"
                          type="date"
                          placeholder={GuestManagementPageText.D_O_B}
                          className="form-control  input_field_custom4"
                          defaultValue={startDate}
                          onChange={(e) => setStartDate(e.target.value)}
                          max={computeTodayDate()}
                        />
                      </div>
                    </div>
                    <div className="col-lg-2 col-sm-4 bottomAlgin">
                      <label className="labelView">
                        {GuestManagementPageText.End_Date}
                      </label>
                      <div className="person__calenderFrame_image image_icon_position1 ">
                        <input
                          id="endDate"
                          type="date"
                          placeholder={GuestManagementPageText.D_O_B}
                          className="form-control  input_field_custom4"
                          defaultValue={endDate}
                          onChange={(e) => setEndDate(e.target.value)}
                          max={computeTodayDate()}
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-4 bottomAlgin">
                      <div className="inputDiv2">
                        <label className="labelPointer" htmlFor="selectFilter">
                          <img src={FilterIcon} alt="Shopup Admin" />
                        </label>
                        <select id="selectFilter" onChange={handleSelect}>
                          {options_search.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-5 col-sm-8 bottomAlgin">
                      <div className="inputDiv2">
                        <img src={Search} alt="Shopup Admin" />
                        <input
                          type="text"
                          id="search_data"
                          onInput={(e) => {
                            if (selectedValue === options_search[0].value) {
                              handleAphabetsChange(e);
                            } else if (
                              selectedValue === options_search[1].value
                            ) {
                              handleEmailChange(e);
                            } else if (
                              selectedValue === options_search[2].value
                            ) {
                              handleNumbersChange(e);
                            }
                          }}
                          placeholder={GuestManagementPageText.Search_Guest}
                        />
                        <button
                          type="button"
                          className="btnSearch"
                          onClick={() => search_data()}
                        >
                          {GuestManagementPageText.Search_text}
                        </button>
                      </div>
                    </div>

                    <div
                      className="col-xl-3 col-md-4 mt-3"
                      style={{ marginLeft: "auto", marginRight: "0" }}
                    >
                      <div className="inputDiv2">
                        <label className="labelPointer" htmlFor="selectFilter">
                          <img src={FilterIcon} alt="Shopup Admin" />
                        </label>
                        <input
                          type="text"
                          placeholder={GuestManagementPageText.Guest_Filter}
                          value={searchfilter}
                          onInput={handleIaphabetnumberChange}
                          onChange={handleSearch}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="viewGuest_table ">
                  <div className="viewGuest_table_container ">
                    <div className="row m-0">
                      <div className="col-md-12">
                        <table className="table">
                          <thead>
                            <tr>
                              <th scope="col">
                                <div className="theadStyle imgThead  ">
                                  <img src={GuestIcon} alt="Shopup Admin" />
                                  <span>
                                    {GuestManagementPageText.Guest_Name}
                                  </span>
                                </div>
                              </th>
                              <th scope="col">
                                <div className="theadStyle">
                                  {GuestManagementPageText.Contact_Details}
                                </div>
                              </th>
                              <th scope="col">
                                <div className="theadStyle">
                                  {GuestManagementPageText.Email_ID}
                                </div>
                              </th>
                              <th scope="col">
                                <div className="theadStyle">
                                  {GuestManagementPageText.Booking_text}
                                </div>
                              </th>
                              <th scope="col">
                                <div className="theadStyle">
                                  {GuestManagementPageText.Status}
                                </div>
                              </th>
                            </tr>
                          </thead>
                          <tbody className="tboday">
                            {filteredData.map((option, index) => (
                              <React.Fragment key={index}>
                                <tr className="tableRow tbodyStyle">
                                  <td className="th1 tabledata">
                                    <div className="guest_incenter borderLeftRadius">
                                      <img
                                        src={
                                          StaffImageLinkData + option.user_image
                                        }
                                        onError={(e) =>
                                          (e.target.src = GuestIcon)
                                        }
                                        alt="Staff avatar"
                                        className="staff-avatar image_show2010"
                                      />
                                      {option.full_name}
                                    </div>
                                  </td>
                                  <td className="tabledata">
                                    <div className="guest_incenter shadowOnlyBottom">
                                      {option.user_moblie_no}
                                    </div>
                                  </td>
                                  <td className="tabledata">
                                    <div className="guest_incenter shadowOnlyBottom">
                                      {option.user_email}
                                    </div>
                                  </td>
                                  <td className="tabledata">
                                    <div className="guest_incenter shadowOnlyBottom">
                                      {option.total_order}
                                    </div>
                                  </td>
                                  <td className="th2 tabledata">
                                    <div className="guest_incenterActions borderRightRadius">
                                      <button
                                        type="button"
                                        className={`markVip Mark_Vip m-0 ${
                                          option.active_status === 0
                                            ? "vipMarked"
                                            : "vipMarkedred"
                                        }`}
                                        onClick={() => openModal(option, index)}
                                      >
                                        <p>{option.vipmarkdata}</p>
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                                <tr
                                  key={`spacer-${index}`}
                                  style={{ height: "1rem" }}
                                ></tr>
                              </React.Fragment>
                            ))}
                            {filteredData.length === 0 && (
                              <tr>
                                <td colSpan={8} className="text_align_center border_1px">
                                  No Results Found
                                </td>{" "}
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showModal} onHide={closeModal} centered backdrop="static">
        <Modal.Body className="modal_body">
          <div className="success_img d-flex justify-content-center">
            {/* ... Modal content goes here ... */}
          </div>

          <p>
            Are you sure you want to mark{" "}
            <span className="color_primary">{clickedButton}</span> as Active
            Customer?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="logoutYesBtn"
            onClick={() => confirmVIP(selectedGuestIndex)}
          >
            Yes
          </Button>
          <Button className="logoutNoBtn" onClick={closeModal}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default GuestMngmt;
