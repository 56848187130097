import React, { useState, useEffect } from "react";
import Loader from "./Loader.js";
import { Modal, Button } from "react-bootstrap";
import {
  server_post_data,
  update_socal_media_link,
  get_all_business_data,
} from "../ServiceConnection/serviceconnection.js";
import { handleError, handleURLChange } from "../CommonJquery/CommonJquery.js";
function SocalMedialink() {
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [SelectedValue, setSelectedValue] = useState("");
  const [SelectedValueCall, setSelectedValueCall] = useState("");
  const [flagData, setflagData] = useState("");
  const [filteredData, setfilteredData] = useState([]);

  useEffect(() => {
    master_data_get();
  }, []);

  const master_data_get = async () => {
    setshowLoaderAdmin(true);
    await server_post_data(get_all_business_data, null)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          setfilteredData(Response.data.message.business_data[0]);
        }
        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        console.log(error);
        handleError("network");
        setshowLoaderAdmin(false);
      });
  };
  const master_data_action_update = async (reject_text) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    console.log(reject_text);
    fd.append("flag", flagData);
    fd.append("data", reject_text);
    await server_post_data(update_socal_media_link, fd)
      .then((Response) => {
        setshowLoaderAdmin(false);
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          closeModal();
          master_data_get(1);
        }
      })
      .catch((error) => {
        handleError("network");
        setshowLoaderAdmin(false);
      });
  };

  const [showModal, setShowModal] = useState(false);

  const openModal = (call_name, value, flag) => {
    setflagData(flag);
    setSelectedValue(value);
    setSelectedValueCall(call_name);
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
  };

  const confirmVIP = () => {
    let reject_text = document.getElementById("disc_msg").value;
    master_data_action_update(reject_text);
  };

  return (
    <div className="dashboard">
      {showLoaderAdmin && <Loader />}
      <div className="dashboard_container">
        <div className="page_content">
          <div className="page_content_container">
            <div className="page_body">
              <div className="viewStaff">
                <div className="viewGuest_table ">
                  <div className="viewGuest_table_container ">
                    <div className="row m-0">
                      <div className="col-md-12 ">
                        <table className="table">
                          <tbody className="tboday">
                            <React.Fragment>
                              <tr className="tableRow tbodyStyle">
                                <td className="tabledata">
                                  <div className="guest_incenter shadowOnlyBottom">
                                    Instargram link
                                  </div>
                                </td>

                                <td className="tabledata">
                                  <div className="guest_incenter shadowOnlyBottom">
                                    {filteredData["instargram_link"]}
                                  </div>
                                </td>
                                <td className="th2 tabledata">
                                  <>
                                    <div className="guest_incenterActions borderRightRadius">
                                      <button
                                        type="button"
                                        className="markVip Mark_Vip vipMarked"
                                        onClick={() =>
                                          openModal(
                                            "instargram link",
                                            filteredData["instargram_link"],
                                            1
                                          )
                                        }
                                      >
                                        <p>Update</p>
                                      </button>
                                    </div>
                                  </>
                                </td>
                              </tr>
                              <tr
                                key={`spacer`}
                                style={{ height: "1rem" }}
                              ></tr>
                              <tr className="tableRow  tbodyStyle">
                                <td className="tabledata">
                                  <div className="guest_incenter shadowOnlyBottom">
                                    Facebook link
                                  </div>
                                </td>

                                <td className="tabledata">
                                  <div className="guest_incenter shadowOnlyBottom">
                                    {filteredData["facebook_link"]}
                                  </div>
                                </td>
                                <td className="th2 tabledata">
                                  <>
                                    <div className="guest_incenterActions borderRightRadius">
                                      <button
                                        type="button"
                                        className="markVip Mark_Vip vipMarked"
                                        onClick={() =>
                                          openModal(
                                            "facebook link",
                                            filteredData["facebook_link"],
                                            2
                                          )
                                        }
                                      >
                                        <p>Update</p>
                                      </button>
                                    </div>
                                  </>
                                </td>
                              </tr>
                              <tr
                                key={`spacer`}
                                style={{ height: "1rem" }}
                              ></tr>
                              <tr className="tableRow  tbodyStyle">
                                <td className="tabledata">
                                  <div className="guest_incenter shadowOnlyBottom">
                                    Twitter link
                                  </div>
                                </td>

                                <td className="tabledata">
                                  <div className="guest_incenter shadowOnlyBottom">
                                    {filteredData["twitter_link"]}
                                  </div>
                                </td>
                                <td className="th2 tabledata">
                                  <>
                                    <div className="guest_incenterActions borderRightRadius">
                                      <button
                                        type="button"
                                        className="markVip Mark_Vip vipMarked"
                                        onClick={() =>
                                          openModal(
                                            "Twitter link",
                                            filteredData["twitter_link"],
                                            3
                                          )
                                        }
                                      >
                                        <p>Update</p>
                                      </button>
                                    </div>
                                  </>
                                </td>
                              </tr>
                              <tr
                                key={`spacer`}
                                style={{ height: "1rem" }}
                              ></tr>
                            </React.Fragment>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showModal} onHide={closeModal} centered backdrop="static">
        <Modal.Body className="modal_body">
          {" "}
          <form className="createRightsForm" id="createRightsForm">
            <div className="success_img  justify-content-center">
              <div className={`row`}>
                <div className="inpContainer">
                  <label className="no_prsnl_id d-flex">
                    {SelectedValueCall}
                    <span className="colorred">*</span>
                  </label>

                  <div>
                    <input
                      type="text"
                      id="disc_msg"
                      name="disc_msg"
                      maxLength={100}
                      onInput={handleURLChange}
                      className={`  trio_mandatory  input_field_customPadding form-control`}
                      placeholder="Enter Paste Link"
                      defaultValue={SelectedValue}
                    />
                    <span className="condition_error"></span>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="logoutYesBtn"
            onClick={() => confirmVIP(flagData, "createRightsForm")}
          >
            Yes
          </Button>
          <Button className="logoutNoBtn" onClick={closeModal}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default SocalMedialink;
