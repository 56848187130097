import React, { useState, useEffect } from "react";
import Header from "./Header.js";
import Loader from "./Loader.js";
import GreyArrow from "../assets/greyLeftAr.png";
import FolderImg from "../assets/Folder-pana.png";
import {
  CreateStaffRightText,
  EditStaffPage,
} from "../CommonJquery/WebsiteText.js";
import {
  check_vaild_save,
  combiled_form_data,
  handleSuccessSession,
  handleError,
  empty_form,
  handleIaphabetnumberChange,
  handleNumbersChange,
  handleAphabetsChange,
  handleNumbersDecimalChange,
} from "../CommonJquery/CommonJquery.js";
import {
  server_post_data,
  add_product_webapp,
  search_product_main_database,
  APL_LINK,
} from "../ServiceConnection/serviceconnection.js";
import { Link, useLocation } from "react-router-dom";
import Select from "react-select";
function Add_Product() {
  const location = useLocation();
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [groupedOptions, setgroupedOptions] = useState([]);
  const [dynaicimage, setDynaicimage] = useState(null);
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [imagePath, setimagePath] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [selectedProductData, setSelectedProductData] = useState(null);
  const [stepclick, setstepclick] = useState(0);
  const [selectedproductname, setselectedproductname] = useState("");
  const groupStyles = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  };
  const groupBadgeStyles = {
    backgroundColor: "#EBECF0",
    borderRadius: "2em",
    color: "#172B4D",
    display: "inline-block",
    fontSize: 12,
    fontWeight: "normal",
    lineHeight: "1",
    minWidth: 1,
    padding: "0.16666666666667em 0.5em",
    textAlign: "center",
  };

  const formatGroupLabel = (data) => (
    <div style={groupStyles}>
      <span>{data.label}</span>
      <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
  );

  const handleFileChangedynamic = (keyname) => (event) => {
    const file = event.target.files[0];

    let new_file_name = keyname + "_show";
    if (!file) {
      return;
    }

    if (file && file.type.startsWith("image/")) {
      // Validate file size
      if (file.size <= 500 * 1024) {
        // 500KB in bytes
        handleError("File size is below the minimum limit (500KB).");
        return;
      }

      if (file.size > 2 * 1024 * 1024) {
        // 2MB in bytes
        handleError("File size exceeds the maximum limit (2MB).");
        return;
      }

      const reader = new FileReader();

      reader.onload = () => {
        const img = new Image();
        img.src = reader.result;

        setDynaicimage((prevImages) => ({
          ...prevImages,
          [keyname]: file,
          [new_file_name]: reader.result,
        }));
      };

      reader.readAsDataURL(file);
    } else {
      handleError("Please select a valid image file.");
      event.target.value = ""; // Clear the file input
    }
  };
  const handleSaveChangesdynamic = async (form_data, url_for_save) => {
    let vaild_data = check_vaild_save(form_data);

    if (vaild_data) {
      setshowLoaderAdmin(true);
      let fd_from = combiled_form_data(form_data, dynaicimage);
      fd_from.append("total_image_loop", 3);
      await server_post_data(url_for_save, fd_from)
        .then((Response) => {
          setshowLoaderAdmin(false);
          if (Response.data.error) {
            handleError(Response.data.message);
          } else {
            handleSuccessSession(Response.data.message, "/view_product");
            empty_form(form_data);
          }
        })
        .catch((error) => {
          console.log(error);
          setshowLoaderAdmin(false);
        });
    }
  };

  useEffect(() => {
    master_data_get();
  }, []);

  useEffect(() => {}, [stepclick]);

  const master_data_get = async () => {
    setshowLoaderAdmin(true);
    await server_post_data(search_product_main_database, null)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          setimagePath(APL_LINK + Response.data.message.product_image_route);
          setgroupedOptions(Response.data.message.data_categoryInformation);
          setOptions(Response.data.message.product_data);
        }
        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        handleError("network");
        setshowLoaderAdmin(false);
      });
  };

  const handleInputChange = (value) => {
    setInputValue(value);
  };

  const filteredOptions = options.filter((option) =>
    option.label.toLowerCase().includes(inputValue.toLowerCase())
  );

  // const addButtonOption = { label: `${inputValue}`, value: inputValue };

  const handleChange = (option) => {
    setSelectedOption(option);
    if (!option) {
      setInputValue("");
      setSelectedOption("");
    } else {
      if (option) {
        const selectedProduct = options.find(
          (product) => product.value === option.value
        );
        if (selectedProduct) {
          setSelectedProductData(selectedProduct);
          setstepclick(1);
        } else {
          setSelectedProductData(null);
          setstepclick(2);
          setselectedproductname(option.value);
        }
      } else {
        setSelectedProductData(null);
        setstepclick(2);
        setselectedproductname(option.value);
      }
    }
  };

  const addButtonOption = {
    label: (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <span>{inputValue}</span>

        <button
          style={{
            marginLeft: "10px",
            border: "none",
            backgroundColor: "transparent",
          }}
        >
          +
        </button>
      </div>
    ),
    value: inputValue,
  };
  return (
    <div className="dashboard">
      {showLoaderAdmin && <Loader />}

      <div className="dashboard_container">
        <div className="page_content">
          <div className="page_content_container">
            <Header />
            <div className="pageCntn_head container-lg">
              <div className="flexCOntauiner">
                <Link to="/view_product">
                  <div className="pageNameDiv">
                    <p>Product Management</p>
                    <img src={GreyArrow} alt="Shopup Admin" />
                  </div>
                </Link>
                <div className="pageNameDiv px-0">
                  <p>
                    <p>Add Product</p>
                  </p>
                  <img src={GreyArrow} alt="Shopup Admin" />
                </div>
              </div>
            </div>

            <div className="page_body">
              <div className="create_staffRights container-lg">
                <form className="createRightsForm" id="createRightsForm">
                  <div className="row m-0">
                    <div className="col-xl-12 col-lg-12 ">
                      <div className="personalForm">
                        <div className="staffForm_container">
                          <div className="row m-0">
                            <div className="col-md-4">
                              <div className="inpContainer">
                                <div className="faqDropdown">
                                  <div className="inpContainer d-flex flex-column">
                                    <div>
                                      <label className="no_prsnl_id">
                                        Search Product
                                        <span className="colorred">*</span>
                                      </label>
                                    </div>
                                    <div>
                                      <Select
                                        aria-labelledby="aria-label"
                                        inputId="aria-example-input"
                                        name="aria-live-color"
                                        isClearable
                                        isSearchable
                                        options={
                                          inputValue &&
                                          !filteredOptions.some(
                                            (option) =>
                                              option.label.toLowerCase() ===
                                              inputValue.toLowerCase()
                                          )
                                            ? [
                                                ...filteredOptions,
                                                addButtonOption,
                                              ]
                                            : filteredOptions
                                        }
                                        onInputChange={handleInputChange}
                                        onChange={handleChange}
                                        value={selectedOption}
                                      />
                                      <span className="condition_error"></span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {stepclick == 2 && (
                              <div className="col-md-8 bottomAlgin">
                                <div>
                                  <p className="notFond">
                                    Product not found! Please add this product.{" "}
                                  </p>
                                </div>
                              </div>
                            )}
                            {stepclick === 1 ? (
                              <>
                                <div>
                                  <div className="col-lg-8 my-4">
                                    <div className="productDetails">
                                      <div className="productDetails_container">
                                        <div className="productHead">
                                          <div>
                                            <h5 className="mb-3">
                                              <span>Product: </span>
                                              {selectedProductData.product_name}
                                            </h5>
                                            <div className="productdetail productdetail2">
                                              <p>
                                                <span>Quantity: </span>
                                                {
                                                  selectedProductData.product_qty
                                                }{" "}
                                                {
                                                  selectedProductData.product_qty_unit
                                                }
                                              </p>
                                              <p>
                                                <span>Description: </span>
                                                {
                                                  selectedProductData.product_detail
                                                }{" "}
                                                {
                                                  selectedProductData.product_highlight
                                                }
                                              </p>
                                            </div>
                                          </div>
                                          <div className="productImg2">
                                            <img
                                              src={`${imagePath}${selectedProductData.product_image}`}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="row hidden">
                                  <input
                                    type="hidden"
                                    id="select_category_id"
                                    name="select_category_id"
                                    className={`    input_field_customPadding form-control`}
                                    placeholder="Category Name"
                                    defaultValue={
                                      selectedProductData.main_category_id +
                                        "~@~" +
                                        selectedProductData.sub_category_id ||
                                      ""
                                    }
                                  />
                                  <input
                                    type="text"
                                    id="product_name1111"
                                    name="product_name"
                                    minLength={4}
                                    maxLength={100}
                                    className={`    input_field_customPadding form-control`}
                                    placeholder="Product Name"
                                    onInput={handleIaphabetnumberChange}
                                    defaultValue={
                                      selectedProductData.product_name || ""
                                    }
                                  />
                                  <input
                                    type="text"
                                    id="product_id"
                                    name="product_id"
                                    minLength={4}
                                    maxLength={100}
                                    className={`    input_field_customPadding form-control`}
                                    placeholder="Product Name"
                                    onInput={handleIaphabetnumberChange}
                                    defaultValue={
                                      selectedProductData.primary_id || "0"
                                    }
                                  />

                                  <input
                                    type="text"
                                    id="product_qty"
                                    name="product_qty"
                                    maxLength={10}
                                    className={`    input_field_customPadding form-control`}
                                    placeholder="Product Qty"
                                    onInput={handleNumbersChange}
                                    defaultValue={
                                      selectedProductData.product_qty || ""
                                    }
                                  />
                                  <input
                                    type="text"
                                    id="product_qty_unit"
                                    name="product_qty_unit"
                                    maxLength={10}
                                    className={`    input_field_customPadding form-control`}
                                    placeholder="Product Qty Unit Name"
                                    onInput={handleAphabetsChange}
                                    defaultValue={
                                      selectedProductData.product_qty_unit || ""
                                    }
                                  />
                                  <input
                                    type="text"
                                    id="product_detail"
                                    name="product_detail"
                                    maxLength={300}
                                    minLength={4}
                                    className={`    input_field_customPadding form-control`}
                                    placeholder="Product Details"
                                    onInput={handleIaphabetnumberChange}
                                    defaultValue={
                                      selectedProductData.product_detail || ""
                                    }
                                  />
                                  <input
                                    type="text"
                                    id="product_highlight"
                                    name="product_highlight"
                                    maxLength={300}
                                    minLength={4}
                                    className={`    input_field_customPadding form-control`}
                                    placeholder="Product Highlight"
                                    onInput={handleIaphabetnumberChange}
                                    defaultValue={
                                      selectedProductData.product_highlight ||
                                      ""
                                    }
                                  />
                                </div>
                                <div className="row">
                                  <div className={`col-md-4`}>
                                    <div className="inpContainer">
                                      <label className="no_prsnl_id">
                                        Product Price
                                        <span className="colorred">*</span>
                                      </label>
                                      <div>
                                        <input
                                          type="text"
                                          id="product_price"
                                          name="product_price"
                                          maxLength={10}
                                          className={`  trio_mandatory  input_field_customPadding form-control`}
                                          placeholder="Product Price"
                                          onInput={handleNumbersChange}
                                        />

                                        <span className="condition_error"></span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className={`col-md-4`}>
                                    <div className="inpContainer">
                                      <label className="no_prsnl_id">
                                        Product Discount (%)
                                        <span className="colorred">*</span>
                                      </label>
                                      <div>
                                        <input
                                          type="text"
                                          id="product_disocunt_per"
                                          name="product_disocunt_per"
                                          maxLength={2}
                                          className={`  trio_mandatory  input_field_customPadding form-control`}
                                          placeholder="Product Discount"
                                          onInput={handleNumbersChange}
                                        />

                                        <span className="condition_error"></span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="createRightBtnDiv">
                                  <button
                                    className="btn btn-primary Create_Right_Btn btnSave"
                                    type="button"
                                    onClick={() =>
                                      handleSaveChangesdynamic(
                                        "createRightsForm",
                                        add_product_webapp
                                      )
                                    }
                                  >
                                    {location.pathname.includes("/edit_product")
                                      ? CreateStaffRightText.save_bttn
                                      : CreateStaffRightText.Create_text}
                                  </button>
                                </div>
                              </>
                            ) : stepclick === 2 ? (
                              <>
                                <div className={`row`}>
                                  <input
                                    type="hidden"
                                    id="product_id"
                                    name="product_id"
                                    minLength={4}
                                    maxLength={100}
                                    className={`    input_field_customPadding form-control`}
                                    placeholder="Product Name"
                                    onInput={handleIaphabetnumberChange}
                                    defaultValue={0}
                                  />
                                  <div className={`col-md-3 `}>
                                    <div className="inpContainer">
                                      <div className="display_grid">
                                        <label className="no_prsnl_id">
                                          Category Name
                                          <span className="colorred">*</span>
                                        </label>
                                        <Select
                                          options={groupedOptions}
                                          name="select_category_id"
                                          formatGroupLabel={formatGroupLabel}
                                          onop
                                        />

                                        <span className="condition_error"></span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className={`col-md-3`}>
                                    <div className="inpContainer">
                                      <label className="no_prsnl_id">
                                        Product Name
                                        <span className="colorred">*</span>
                                      </label>
                                      <div>
                                        <input
                                          type="text"
                                          id="product_nameedit"
                                          name="product_name"
                                          defaultValue={selectedproductname}
                                          minLength={4}
                                          maxLength={100}
                                          className={`  trio_mandatory  input_field_customPadding form-control`}
                                          placeholder="Product Name"
                                          onInput={handleIaphabetnumberChange}
                                        />

                                        <span className="condition_error"></span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className={`col-md-3`}>
                                    <div className="inpContainer">
                                      <label className="no_prsnl_id">
                                        Product Qty
                                        <span className="colorred">*</span>
                                      </label>
                                      <div>
                                        <input
                                          type="text"
                                          id="product_qty"
                                          name="product_qty"
                                          maxLength={10}
                                          className={`  trio_mandatory  input_field_customPadding form-control`}
                                          placeholder="Product Qty"
                                          onInput={handleNumbersChange}
                                        />

                                        <span className="condition_error"></span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className={`col-md-3`}>
                                    <div className="inpContainer">
                                      <label className="no_prsnl_id">
                                        Product Qty Unit Ex. (GM KG ML)
                                        <span className="colorred">*</span>
                                      </label>
                                      <div>
                                        <input
                                          type="text"
                                          id="product_qty_unit"
                                          name="product_qty_unit"
                                          maxLength={10}
                                          className={`  trio_mandatory  input_field_customPadding form-control`}
                                          placeholder="Product Qty Unit Name"
                                          onInput={handleAphabetsChange}
                                        />

                                        <span className="condition_error"></span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className={`col-md-6`}>
                                    <div className="inpContainer">
                                      <label className="no_prsnl_id">
                                        Product Details
                                        <span className="colorred">*</span>
                                      </label>
                                      <div>
                                        <textarea
                                          id="product_detail"
                                          name="product_detail"
                                          maxLength={300}
                                          minLength={4}
                                          className={`  trio_mandatory  input_field_customPadding form-control`}
                                          placeholder="Product Details"
                                          onInput={handleIaphabetnumberChange}
                                        />

                                        <span className="condition_error"></span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className={`col-md-6`}>
                                    <div className="inpContainer">
                                      <label className="no_prsnl_id">
                                        Product Highlight
                                      </label>
                                      <div>
                                        <textarea
                                          id="product_highlight"
                                          name="product_highlight"
                                          maxLength={300}
                                          minLength={4}
                                          className={`    input_field_customPadding form-control`}
                                          placeholder="Product Highlight"
                                          onInput={handleIaphabetnumberChange}
                                        />

                                        <span className="condition_error"></span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className={`col-md-4`}>
                                    <div className="inpContainer">
                                      <label className="no_prsnl_id">
                                        Product Price
                                        <span className="colorred">*</span>
                                      </label>
                                      <div>
                                        <input
                                          type="text"
                                          id="product_price"
                                          name="product_price"
                                          maxLength={10}
                                          className={`  trio_mandatory  input_field_customPadding form-control`}
                                          placeholder="Product Price"
                                          onInput={handleNumbersChange}
                                        />

                                        <span className="condition_error"></span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className={`col-md-4`}>
                                    <div className="inpContainer">
                                      <label className="no_prsnl_id">
                                        Product Discount (%)
                                        <span className="colorred">*</span>
                                      </label>
                                      <div>
                                        <input
                                          type="text"
                                          id="product_disocunt_per"
                                          name="product_disocunt_per"
                                          maxLength={2}
                                          className={`  trio_mandatory  input_field_customPadding form-control`}
                                          placeholder="Product Discount"
                                          onInput={handleNumbersChange}
                                        />

                                        <span className="condition_error"></span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className={`row `}>
                                  <div className={`col-md-3`}>
                                    <div className="inpContainer flexColum">
                                      <label className="no_prsnl_id">
                                        Main Product Image
                                        <br></br>
                                        <span className="colorred imageSizeText">
                                          Size 600*600 max (max. size 2 MB)
                                        </span>
                                      </label>
                                      <div>
                                        <div className="">
                                          {dynaicimage &&
                                          dynaicimage.doctor_image0_show ? (
                                            <img
                                              src={
                                                dynaicimage.doctor_image0_show
                                              }
                                              onError={(e) =>
                                                (e.target.src = FolderImg)
                                              }
                                              alt="Shopup Admin"
                                              className="image_show202 "
                                            />
                                          ) : (
                                            <img
                                              src={FolderImg}
                                              alt="Shopup Admin"
                                              className="image_show202 "
                                            />
                                          )}
                                        </div>
                                        <input
                                          type="file"
                                          name="doctor_image0"
                                          onChange={handleFileChangedynamic(
                                            "doctor_image0"
                                          )}
                                          className={`${
                                            dynaicimage &&
                                            dynaicimage.doctor_image0_show
                                              ? ""
                                              : "trio_mandatory"
                                          } form-control`}
                                          accept=".jpg,.jpeg,.png"
                                          hidden
                                          id="imgInput"
                                        />
                                        <label
                                          className="imgInpBtn m-auto"
                                          htmlFor="imgInput"
                                        >
                                          {dynaicimage &&
                                          dynaicimage.doctor_image0_show
                                            ? "Change Image"
                                            : " Choose Image"}
                                        </label>

                                        <span className="condition_error"></span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className={`col-md-3`}>
                                    <div className="inpContainer flexColum">
                                      <label className="no_prsnl_id">
                                        Product Image 2<br></br>
                                        <span className="colorred imageSizeText">
                                          Size 600*600 max (max. size 2 MB)
                                        </span>
                                      </label>
                                      <div>
                                        <div className="">
                                          {dynaicimage &&
                                          dynaicimage.doctor_image1_show ? (
                                            <img
                                              src={
                                                dynaicimage.doctor_image1_show
                                              }
                                              onError={(e) =>
                                                (e.target.src = FolderImg)
                                              }
                                              alt="Shopup Admin"
                                              className="image_show202 "
                                            />
                                          ) : (
                                            <img
                                              src={FolderImg}
                                              alt="Shopup Admin"
                                              className="image_show202 "
                                            />
                                          )}
                                        </div>
                                        <input
                                          type="file"
                                          name="doctor_image1"
                                          onChange={handleFileChangedynamic(
                                            "doctor_image1"
                                          )}
                                          className={`${
                                            dynaicimage &&
                                            dynaicimage.doctor_image1_show
                                              ? ""
                                              : "trio_mandatory"
                                          } form-control`}
                                          accept=".jpg,.jpeg,.png"
                                          hidden
                                          id="imgInput2"
                                        />
                                        <label
                                          className="imgInpBtn m-auto"
                                          htmlFor="imgInput2"
                                        >
                                          {dynaicimage &&
                                          dynaicimage.doctor_image1_show
                                            ? "Change Image"
                                            : " Choose Image"}
                                        </label>

                                        <span className="condition_error"></span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className={`col-md-3`}>
                                    <div className="inpContainer flexColum">
                                      <label className="no_prsnl_id">
                                        Product Image 3<br></br>
                                        <span className="colorred imageSizeText">
                                          Size 600*600 max (max. size 2 MB)
                                        </span>
                                      </label>
                                      <div>
                                        <div className="">
                                          {dynaicimage &&
                                          dynaicimage.doctor_image2_show ? (
                                            <img
                                              src={
                                                dynaicimage.doctor_image2_show
                                              }
                                              onError={(e) =>
                                                (e.target.src = FolderImg)
                                              }
                                              alt="Shopup Admin"
                                              className="image_show202 "
                                            />
                                          ) : (
                                            <img
                                              src={FolderImg}
                                              alt="Shopup Admin"
                                              className="image_show202 "
                                            />
                                          )}
                                        </div>
                                        <input
                                          type="file"
                                          name="doctor_image2"
                                          onChange={handleFileChangedynamic(
                                            "doctor_image2"
                                          )}
                                          className={`${
                                            dynaicimage &&
                                            dynaicimage.doctor_image2_show
                                              ? ""
                                              : "trio_mandatory"
                                          } form-control`}
                                          accept=".jpg,.jpeg,.png"
                                          hidden
                                          id="imgInput3"
                                        />
                                        <label
                                          className="imgInpBtn m-auto"
                                          htmlFor="imgInput3"
                                        >
                                          {dynaicimage
                                            ? "Change Image"
                                            : " Choose Image"}
                                        </label>

                                        <span className="condition_error"></span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className={`col-md-3`}>
                                    <div className="inpContainer flexColum">
                                      <label className="no_prsnl_id">
                                        Product Image 4<br></br>
                                        <span className="colorred imageSizeText">
                                          Size 600*600 max (max. size 2 MB)
                                        </span>
                                      </label>
                                      <div>
                                        <div className="">
                                          {dynaicimage &&
                                          dynaicimage.doctor_image3_show ? (
                                            <img
                                              src={
                                                dynaicimage.doctor_image3_show
                                              }
                                              onError={(e) =>
                                                (e.target.src = FolderImg)
                                              }
                                              alt="Shopup Admin"
                                              className="image_show202 "
                                            />
                                          ) : (
                                            <img
                                              src={FolderImg}
                                              alt="Shopup Admin"
                                              className="image_show202 "
                                            />
                                          )}
                                        </div>
                                        <input
                                          type="file"
                                          name="doctor_image3"
                                          onChange={handleFileChangedynamic(
                                            "doctor_image3"
                                          )}
                                          className={`${
                                            dynaicimage &&
                                            dynaicimage.doctor_image3_show
                                              ? ""
                                              : "trio_mandatory"
                                          } form-control`}
                                          accept=".jpg,.jpeg,.png"
                                          hidden
                                          id="imgInput4"
                                        />
                                        <label
                                          className="imgInpBtn m-auto"
                                          htmlFor="imgInput4"
                                        >
                                          {dynaicimage &&
                                          dynaicimage.doctor_image3_show
                                            ? "Change Image"
                                            : " Choose Image"}
                                        </label>

                                        <span className="condition_error"></span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="createRightBtnDiv">
                                  <button
                                    className="btn btn-primary Create_Right_Btn btnSave"
                                    type="button"
                                    onClick={() =>
                                      handleSaveChangesdynamic(
                                        "createRightsForm",
                                        add_product_webapp
                                      )
                                    }
                                  >
                                    {location.pathname.includes("/edit_product")
                                      ? CreateStaffRightText.save_text
                                      : CreateStaffRightText.Create_text}
                                  </button>
                                </div>
                              </>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Add_Product;
