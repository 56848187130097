import React, { useState, useEffect } from "react";
import Loader from "../Components/Loader.js";
import {
  handleError,
  check_vaild_save,
  combiled_form_data,
  handleAphabetsChange,
  handleSuccess,
  handleAphabetswithhashChange,
} from "../CommonJquery/CommonJquery.js";
import {
  server_post_data,
  update_business_address,
  get_all_business_data,
} from "../ServiceConnection/serviceconnection.js";
const UpdateAddress = () => {
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [editStaffDataDetails, seteditStaffDataDetails] = useState([]);
  const [editorDataMainID, setEditorDatMainID] = useState("0");

  useEffect(() => {
    const input = document.getElementById("searchInput");
    const autocomplete = new window.google.maps.places.Autocomplete(input);

    autocomplete.addListener("place_changed", function () {
      const place = autocomplete.getPlace();

      let full_address = place.address_components;
      let formatted_address = place.formatted_address;
      let geometry_data = place.geometry;
      let length_data = place.address_components.length;
      let citys = "";
      let state = "";
      let country = "";
      let tehsil = "";
      let postal_code = "";
      for (let i = 0; i < length_data; i++) {
        if (full_address[i].types[0] === "administrative_area_level_1") {
          state = full_address[i].long_name;
        } else if (full_address[i].types[0] === "country") {
          country = full_address[i].long_name;
        } else if (full_address[i].types[0] === "administrative_area_level_2") {
          citys = full_address[i].long_name;
        } else if (full_address[i].types[0] === "locality") {
          tehsil = full_address[i].long_name;
        } else if (full_address[i].types[0] === "postal_code") {
          postal_code = full_address[i].long_name;
        }
      }
      if (tehsil !== "") {
        citys = tehsil;
      }
      document.getElementById("searchInput").value = formatted_address;
      document.getElementById("business_city").value = citys;
      document.getElementById("business_state").value = state;
      document.getElementById("business_country").value = country;
      document.getElementById("business_pincode").value = postal_code;
      document.getElementById("business_longitude").value =
        geometry_data.location.lng();
      document.getElementById("business_latitude").value =
        geometry_data.location.lat();
    });
  }, []);

  useEffect(() => {
    master_data_get();
  }, []);

  const master_data_get = async () => {
    setshowLoaderAdmin(true);
    await server_post_data(get_all_business_data, null)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          if (Response.data.message.business_data.length > 0) {
            seteditStaffDataDetails(Response.data.message.business_data[0]);
            setEditorDatMainID(
              Response.data.message.business_data[0].primary_id
            );
          }
        }

        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        handleError("network");
        setshowLoaderAdmin(false);
      });
  };

  const handleSaveChangesdynamic = async (form_data, url_for_save) => {
    let vaild_data = check_vaild_save(form_data);
    console.log(vaild_data);
    if (vaild_data) {
      setshowLoaderAdmin(true);
      let fd_from = combiled_form_data(form_data, null);
      fd_from.append("call_id", editorDataMainID);
      await server_post_data(url_for_save, fd_from)
        .then((Response) => {
          setshowLoaderAdmin(false);
          if (Response.data.error) {
            handleError(Response.data.message);
          } else {
            master_data_get("", "", "1", editorDataMainID);
            handleSuccess(Response.data.message);
          }
        })
        .catch((error) => {
          setshowLoaderAdmin(false);
        });
    }
  };

  return (
    <div>
      {showLoaderAdmin && <Loader />}
      <form id="addNewStaff">
        <div className="event-box blog-data">
          <div className="form-group seoAddForm">
            <div className="row m-0">
              <div className="col-md-6 mt-2">
                <label className="no_prsnl_id">Business Address </label>
                <textarea
                  style={{ height: "100px" }}
                  type="text"
                  name="business_address"
                  defaultValue={editStaffDataDetails.business_address || ""}
                  id="business_address"
                  onInput={handleAphabetsChange}
                  className="form-control trio_mandatory"
                  placeholder="Enter Full Address*"
                />
                <span className="condition_error"></span>
              </div>
              <div className="col-md-6">
                <div className="inpContainer inpContainerSeo mt-2">
                  <label className="no_prsnl_id">Map Address</label>
                  <textarea
                    style={{ height: "100px" }}
                    type="text"
                    name="business_map_address"
                    id="searchInput"
                    defaultValue={
                      editStaffDataDetails.business_map_address || ""
                    }
                    className="form-control trio_mandatory"
                    placeholder="Enter Map Address*"
                  />
                  <span className="condition_error"></span>
                </div>
              </div>
              <div className="col-md-6 hidden">
                <div className="inpContainer inpContainerSeo mt-2">
                  <label className="no_prsnl_id">City </label>
                  <input
                    type="text"
                    name="business_city"
                    id="business_city"
                    defaultValue={editStaffDataDetails.business_city || ""}
                    onInput={handleAphabetswithhashChange}
                    className="form-control trio_mandatory"
                    placeholder="Enter City*"
                  />
                  <span className="condition_error"></span>
                </div>
              </div>
              <div className="col-md-6 hidden">
                <div className="inpContainer inpContainerSeo mt-2">
                  <label className="no_prsnl_id">Pin code </label>
                  <input
                    type="text"
                    name="business_pincode"
                    id="business_pincode"
                    defaultValue={editStaffDataDetails.business_pincode || ""}
                    onInput={handleAphabetswithhashChange}
                    className="form-control trio_mandatory"
                    placeholder="Enter Pin code*"
                  />
                  <span className="condition_error"></span>
                </div>
              </div>
              <div className="col-md-6 hidden">
                <div className="inpContainer inpContainerSeo mt-2">
                  <label className="no_prsnl_id">State</label>
                  <input
                    type="text"
                    name="business_state"
                    id="business_state"
                    defaultValue={editStaffDataDetails.business_state || ""}
                    className="form-control trio_mandatory"
                    placeholder="Enter State Name*"
                  />
                  <span className="condition_error"></span>
                </div>
              </div>
              <div className="col-md-6 hidden">
                <div className="inpContainer inpContainerSeo mt-2">
                  <label className="no_prsnl_id">Country</label>
                  <input
                    type="text"
                    name="business_country"
                    id="business_country"
                    defaultValue={editStaffDataDetails.business_country || ""}
                    className="form-control trio_mandatory"
                    placeholder="Enter Country Name*"
                  />
                  <span className="condition_error"></span>
                </div>
              </div>

              <div className="col-md-6 hidden">
                <div className="inpContainer inpContainerSeo mt-2">
                  <label className="no_prsnl_id">Business Latitude </label>
                  <input
                    type="text"
                    name="business_latitude"
                    id="business_latitude"
                    defaultValue={editStaffDataDetails.business_latitude || ""}
                    onInput={handleAphabetswithhashChange}
                    className="form-control trio_mandatory"
                    placeholder="Enter Latitude code*"
                  />
                  <span className="condition_error"></span>
                </div>
              </div>
              <div className="col-md-6 hidden">
                <div className="inpContainer inpContainerSeo mt-2">
                  <label className="no_prsnl_id">Business Longitude </label>
                  <input
                    type="text"
                    name="business_longitude"
                    id="business_longitude"
                    defaultValue={editStaffDataDetails.business_longitude || ""}
                    onInput={handleAphabetswithhashChange}
                    className="form-control trio_mandatory"
                    placeholder="Enter Longitude code*"
                  />
                  <span className="condition_error"></span>
                </div>
              </div>
              <div className="col-md-12 ">
                <div className="addBlogBtnDiv">
                  <button
                    type="button"
                    onClick={() =>
                      handleSaveChangesdynamic(
                        "addNewStaff",
                        update_business_address
                      )
                    }
                    className="btn btn-secondary mt-3 save-cap-btn"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
export default UpdateAddress;
