import React, { useState, useEffect } from "react";
import Header from "./Header.js";
import Loader from "./Loader.js";
import GreyArrow from "../assets/greyLeftAr.png";
import FolderImg from "../assets/Folder-pana.png";
import {
  CreateStaffRightText,
  EditStaffPage,
} from "../CommonJquery/WebsiteText.js";
import {
  check_vaild_save,
  combiled_form_data,
  handleSuccessSession,
  handleError,
  empty_form,
} from "../CommonJquery/CommonJquery.js";
import {
  server_post_data,
  save_update_banner,
  get_all_bannerInformation,
  APL_LINK,
} from "../ServiceConnection/serviceconnection.js";
import { Link, useLocation } from "react-router-dom";

function Add_Banner() {
  const location = useLocation();
  const currentUrl = location.pathname.substring(1);
  console.log(currentUrl);
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [editorDataMainID, setEditorDatMainID] = useState("0");
  const [editOldImageData, seteditOldImageData] = useState([]);
  const [editOldImageData1, seteditOldImageData1] = useState([]);
  const [editStaffData, seteditStaffData] = useState([]);
  const [dynaicimage, setDynaicimage] = useState(null);
  const handleFileChangedynamic = (keyname) => (event) => {
    const file = event.target.files[0];

    let new_file_name = keyname + "_show";
    if (!file) {
      return;
    }

    if (file && file.type.startsWith("image/")) {
      // Validate file size
      if (file.size < 200 * 1024) {
        // 200KB in bytes
        alert("File size is below the minimum limit (200KB).");
        return;
      }

      if (file.size > 500 * 1024) {
        // 500KB in bytes
        alert("File size exceeds the maximum limit (500KB).");
        return;
      }

      const reader = new FileReader();

      reader.onload = () => {
        const img = new Image();
        img.src = reader.result;

        setDynaicimage((prevImages) => ({
          ...prevImages,
          [keyname]: file,
          [new_file_name]: reader.result,
        }));
      };

      reader.readAsDataURL(file);
    } else {
      alert("Please select a valid image file.");
      event.target.value = ""; // Clear the file input
    }
  };
  const handleSaveChangesdynamic = async (form_data, url_for_save) => {
    let vaild_data = check_vaild_save(form_data);

    if (vaild_data) {
      setshowLoaderAdmin(true);
      let fd_from = combiled_form_data(form_data, dynaicimage);
      fd_from.append("main_id", editorDataMainID);
      fd_from.append("old_image_link", editOldImageData);
      fd_from.append("old_image_link1", editOldImageData1);
      await server_post_data(url_for_save, fd_from)
        .then((Response) => {
          setshowLoaderAdmin(false);
          if (Response.data.error) {
            handleError(Response.data.message);
          } else {
            master_data_get("", "", "1", "0");
            handleSuccessSession(Response.data.message, "/view_banner");
            empty_form(form_data);
          }
        })
        .catch((error) => {
          setshowLoaderAdmin(false);
        });
    }
  };

  useEffect(() => {
    master_data_get();
  }, []);

  const master_data_get = async () => {
    const url = currentUrl;
    const parts = url.split("/");
    let call_id = "0";
    if (parts.length !== 1) {
      call_id = parts[1];
    }
    if (call_id != "0") {
      setshowLoaderAdmin(true);
      const fd = new FormData();
      fd.append("call_id", call_id);
      fd.append("flag", "3");
      await server_post_data(get_all_bannerInformation, fd)
        .then((Response) => {
          if (Response.data.error) {
            handleError(Response.data.message);
          } else {
            if (Response.data.message.data_bannerInformation.length > 0) {
              setEditorDatMainID(
                Response.data.message.data_bannerInformation[0].primary_id
              );
              seteditOldImageData(
                Response.data.message.data_bannerInformation[0].banner_image
              );
              setDynaicimage({
                doctor_image_show:
                  APL_LINK +
                  Response.data.message.data_imagelink +
                  Response.data.message.data_bannerInformation[0].banner_image,
              });
              seteditOldImageData1(
                Response.data.message.data_bannerInformation[0].banner_image1
              );
              seteditStaffData(Response.data.message.data_bannerInformation[0]);
            }
          }
          setshowLoaderAdmin(false);
        })
        .catch((error) => {
          handleError("network");
          setshowLoaderAdmin(false);
        });
    }
  };

  const options_search = [
    { value: 0, label: "Top" },
    { value: 1, label: "Bottom" },
  ];

  return (
    <div className="dashboard">
      {showLoaderAdmin && <Loader />}

      <div className="dashboard_container">
        <div className="page_content">
          <div className="page_content_container">
            <Header />
            <div className="pageCntn_head container-lg">
              <div className="flexCOntauiner">
                <Link to="/view_banner">
                  <div className="pageNameDiv">
                    <p>Banner Management</p>
                    <img src={GreyArrow} alt="Shopup Admin" />
                  </div>
                </Link>
                <div className="pageNameDiv px-0">
                  <p>
                    <p>
                      {currentUrl === "add_banner"
                        ? "Add Banner"
                        : "Edit Banner"}
                    </p>
                  </p>
                  <img src={GreyArrow} alt="Shopup Admin" />
                </div>
              </div>
            </div>

            <div className="page_body">
              <div className="create_staffRights container-lg">
                <form className="createRightsForm" id="createRightsForm">
                  <div className="row m-0">
                    <div className="col-xl-8 col-lg-10 ">
                      <div className="personalForm">
                        <div className="staffForm_container">
                          <div className="row m-0">
                            <div className="col-md-4">
                              <div className="inpContainer">
                                <div className="faqDropdown">
                                  <div className="inpContainer">
                                    <label className="no_prsnl_id">
                                      Position
                                      <span className="colorred">*</span>
                                    </label>
                                    <div>
                                      <select
                                        id="banner_position"
                                        name="banner_position"
                                        // className={`trio_mandatory form-control input_field_custom2 input_field_customPadding  `}
                                        className="input_field_custom2  input_field_customPadding trio_mandatory "
                                      >
                                        {options_search.map((option) => (
                                          <option
                                            key={option.value}
                                            value={option.value}
                                            selected={
                                              editStaffData.banner_position ===
                                              option.value
                                                ? true
                                                : false
                                            }
                                          >
                                            {option.label}
                                          </option>
                                        ))}
                                      </select>
                                      <span className="condition_error"></span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className={`col-md-8`}>
                              <div className="inpContainer">
                                <div>
                                  <label className="no_prsnl_id">
                                    Image
                                    <span className="colorred imageSizeText">
                                      Size 1200*600 max (max. size 500 KB)
                                    </span>
                                  </label>
                                </div>
                                <br />
                                <div className="w-100 d-flex justify-content-start">
                                  <input
                                    type="file"
                                    name="doctor_image"
                                    onChange={handleFileChangedynamic(
                                      "doctor_image"
                                    )}
                                    className={`${
                                      dynaicimage &&
                                      dynaicimage.doctor_image_show
                                        ? ""
                                        : "trio_mandatory"
                                    } form-control`}
                                    accept=".jpg,.jpeg,.png"
                                    hidden
                                    id="imgInput"
                                  />
                                  <label
                                    className="imgInpBtn"
                                    htmlFor="imgInput"
                                  >
                                    {dynaicimage
                                      ? "Change Image"
                                      : " Choose Image"}
                                  </label>
                                  <span className="condition_error"></span>
                                </div>
                              </div>
                            </div>

                            <div className="">
                              {dynaicimage && dynaicimage.doctor_image_show && (
                                <img
                                  src={dynaicimage.doctor_image_show}
                                  onError={(e) => (e.target.src = FolderImg)}
                                  alt="Shopup Admin"
                                  className="image_show101"
                                />
                              )}
                            </div>
                          </div>
                          <div className="createRightBtnDiv">
                            <button
                              className="btn btn-primary Create_Right_Btn btnSave"
                              type="button"
                              onClick={() =>
                                handleSaveChangesdynamic(
                                  "createRightsForm",
                                  save_update_banner
                                )
                              }
                            >
                              {location.pathname.includes("/edit_banner")
                                ? EditStaffPage.save_text
                                : CreateStaffRightText.Create_text}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Add_Banner;
