import React, { useEffect, useState, useRef } from "react";
import Header from "./Header";
import Loader from "./Loader.js";
import Info from "../assets/infoIconcr.png";
import dropdown from "../assets/arrow_drop_down_24px.svg";
import DownloadImg from "../assets/print.svg";
import Chart from "react-apexcharts";
import { Pagination } from "react-bootstrap";
import { Modal, Button } from "react-bootstrap";
import {
  server_post_data,
  get_all_analyticsReservation,
  update_customer_order_status,
} from "../ServiceConnection/serviceconnection.js";
import {
  handleError,
  computeTodayDate,
  computeplussevendays,
  check_vaild_save,
  handleIaphabetnumberChange,
  formatDateStringdot,
  TimeformateChangeDtae,
} from "../CommonJquery/CommonJquery";
import { OrdersManagementPageText } from "../CommonJquery/WebsiteText.js";
import html2canvas from "html2canvas";
import {
  options_select_drop_feedback,
  Analytics_text,
} from "./../CommonJquery/WebsiteText";
import { Link } from "react-router-dom";

function Analytics() {
  const [SelectedData, setSelectedData] = useState([]);
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [FirstTimeData, setFirstTimeData] = useState(false);
  const [customDateActive, setCustomDateActive] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [selected, setIsSelected] = useState(options_select_drop_feedback[0]);
  const [series, setseries] = useState([0, 0, 0]);
  const [filteredData, setfilteredData] = useState([]);
  const [Statics, setStatics] = useState([]);
  const [startDate, setStartDate] = useState(computeplussevendays());
  const [endDate, setEndDate] = useState(computeTodayDate());
  const [RupeesLeft, setsRupeesLeft] = useState("");
  const [RupeesRight, setsRupeesRight] = useState("");
  const handleDownloadButtonClick = () => {
    html2canvas(document.body).then((canvas) => {
      const link = document.createElement("a");
      link.download = "Analytics.png";
      link.href = canvas.toDataURL("image/png");
      link.click();
    });
  };

  // code by shubham jain for custom date functionality
  useEffect(() => {
    master_data_get(selected.value);
  }, []);

  const master_data_get = async (select_type) => {
    let start_date = startDate;
    let end_date = endDate;
    setshowLoaderAdmin(true);
    const fd = new FormData();
    const current_date = new Date(); // Initialize current_date with the current date
    if (select_type === "today") {
      start_date = start_date_fn(current_date);
      end_date = end_date_fn(current_date);
    } else if (select_type === "last_7_days") {
      const sevenDaysAgo = new Date();
      sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
      start_date = start_date_fn(sevenDaysAgo);
      end_date = end_date_fn(current_date);
    } else if (select_type === "this_month") {
      const firstDayOfMonth = new Date(
        current_date.getFullYear(),
        current_date.getMonth(),
        1
      );
      const lastDayOfMonth = new Date(
        current_date.getFullYear(),
        current_date.getMonth() + 1,
        0
      );
      start_date = start_date_fn(firstDayOfMonth);
      end_date = end_date_fn(lastDayOfMonth);
    } else if (select_type === "last_month") {
      const firstDayOfLastMonth = new Date(
        current_date.getFullYear(),
        current_date.getMonth() - 1,
        1
      );
      const lastDayOfLastMonth = new Date(
        current_date.getFullYear(),
        current_date.getMonth(),
        0
      );
      start_date = start_date_fn(firstDayOfLastMonth);
      end_date = end_date_fn(lastDayOfLastMonth);
    } else if (select_type === "this_year") {
      const firstDayOfYear = new Date(current_date.getFullYear(), 0, 1);
      start_date = start_date_fn(firstDayOfYear);
      end_date = end_date_fn(current_date);
    }

    function start_date_fn(start_date) {
      // Formatting start date
      const start_year = start_date.getFullYear();
      const start_month = (start_date.getMonth() + 1)
        .toString()
        .padStart(2, "0");
      const start_day = start_date.getDate().toString().padStart(2, "0");
      return `${start_year}-${start_month}-${start_day}`;
    }

    function end_date_fn(end_date) {
      // Formatting end date
      const end_year = end_date.getFullYear();
      const end_month = (end_date.getMonth() + 1).toString().padStart(2, "0");
      const end_day = end_date.getDate().toString().padStart(2, "0");
      return `${end_year}-${end_month}-${end_day}`;
    }
    fd.append("start_date", start_date);
    fd.append("end_date", end_date);
    fd.append("select_type", select_type);
    await server_post_data(get_all_analyticsReservation, fd)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          setfilteredData(Response.data.message);
          setsRupeesLeft(Response.data.message.RUPEES_ICON_LEFT);
          setsRupeesRight(Response.data.message.RUPEES_ICON_RIGHT);
          setfilteredData(Response.data.message);
          setseries([
            Response.data.message.count_carting_web,
            Response.data.message.count_carting_app,
          ]);

          const barGraph = Response.data.message.view_data.map((posnegObj) => {
            let haveApprovalPendingData = 0;
            let haveArrivedData = 0;
            let haveCompletedData = 0;
            let haveCancelledData = 0;

            let totalApprovalPending = 0;
            let totalArrived = 0;
            let totalCompleted = 0;
            let totalCancelled = 0;

            Response.data.message.reservations.forEach((subObj) => {
              const trimmedEntryDate = String(subObj.month_or_date).replace(
                /^0+/,
                ""
              );
              const trimmedToCheck = posnegObj.tocheck.replace(/^0+/, "");
              if (
                String(trimmedEntryDate) === String(trimmedToCheck) &&
                String(posnegObj.tocheck_year) === String(subObj.month_year)
              ) {
                if (subObj.booking_status_name === "Pending") {
                  totalApprovalPending = subObj.total_count;
                  haveApprovalPendingData = 1;
                }
                if (subObj.booking_status_name === "Arrived") {
                  totalArrived = subObj.total_count;
                  haveArrivedData = 1;
                }
                if (subObj.booking_status_name === "Completed") {
                  totalCompleted = subObj.total_count;
                  haveCompletedData = 1;
                }
                if (subObj.booking_status_name === "Cancelled") {
                  totalCancelled = subObj.total_count;
                  haveCancelledData = 1;
                }
              }
            });

            return {
              total_approval_pending: haveApprovalPendingData
                ? totalApprovalPending
                : 0,
              total_arrived: haveArrivedData ? totalArrived : 0,
              total_completed: haveCompletedData ? totalCompleted : 0,
              total_cancelled: haveCancelledData ? totalCancelled : 0,
            };
          });
          const totalApprovalPendingArray = barGraph.map(
            (item) => item.total_approval_pending
          );
          const totalArrivedArray = barGraph.map((item) => item.total_arrived);
          const totalCompletedArray = barGraph.map(
            (item) => item.total_completed
          );
          const totalCancelledArray = barGraph.map(
            (item) => item.total_cancelled
          );

          const aryList = {
            totalPending: totalApprovalPendingArray,
            totalArrived: totalArrivedArray,
            totalCompleted: totalCompletedArray,
            totalCancelled: totalCancelledArray,
          };
          setStatics(aryList);
        }
        setshowLoaderAdmin(false);
        setFirstTimeData(false);
      })
      .catch((error) => {
        console.log("1", error);
        handleError("network");
        setshowLoaderAdmin(false);
      });
  };

  const search_data = () => {
    master_data_get(selected.value);
  };

  const select_dropdown = (itemsdata) => {
    setIsSelected(itemsdata);
    setIsActive(!isActive);

    if (itemsdata.datepicker_show) {
      setCustomDateActive(true);
    } else {
      setCustomDateActive(false);
      master_data_get(itemsdata.value);
    }
  };

  // code by shubham jain for custom date functionality

  const optionsRadial2 = {
    chart: {
      type: "donut",
    },
    labels: ["Web Covers", "App Covers"],
    colors: ["#007bff", "#509D30"],
    legend: {
      show: false,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  const seriesLine = [
    {
      name: "Pending",
      data: Statics.totalPending,
    },
    {
      name: "Accept",
      data: Statics.totalArrived,
    },
    {
      name: "Completed",
      data: Statics.totalCompleted,
    },
    {
      name: "Cancelled",
      data: Statics.totalCancelled,
    },
  ];

  const optionsLine = {
    chart: {
      height: 350,
      type: "line",
      zoom: {
        enabled: false, // Disable zooming
      },
      toolbar: {
        show: false, // Hide toolbar
      },
    },
    stroke: {
      curve: "smooth", // Smoothened curve for the line
    },
    yaxis: {
      title: {
        text: "No. of Orders",
      },
    },

    colors: ["#509D30", "#007bff", "#FF1212", "#A098AE"],
    markers: {
      size: 6,
      colors: ["#509D30", "#007bff", "#FF1212", "#A098AE"],
      strokeColors: "#fff",
      strokeWidth: 0,
      hover: {
        size: 8,
      },
    },
    grid: {
      show: false, // Hide grid lines
    },
    legend: {
      show: false, // Hide legends
    },
  };

  const tableRef = useRef(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);
  const currentItems = filteredData;

  const totalPageCount = Math.ceil(filteredData.length / itemsPerPage);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const renderPaginationItems = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPageCount; i++) {
      pageNumbers.push(i);
    }

    if (totalPageCount <= 5) {
      return pageNumbers.map((number) => (
        <Pagination.Item
          key={number}
          active={number === currentPage}
          // onClick={() => paginate(number)}
          onClick={() => handlePageClick(number)}
        >
          {number}
        </Pagination.Item>
      ));
    } else {
      const delta = 2;
      const left = currentPage - delta;
      const right = currentPage + delta + 1;
      let pages = [];
      let isEllipsisShown = false;

      for (let i = 1; i <= totalPageCount; i++) {
        if (i === 1 || i === totalPageCount || (i >= left && i < right)) {
          pages.push(i);
        } else if (!isEllipsisShown) {
          pages.push(-1); // -1 indicates ellipsis
          isEllipsisShown = true;
        }
      }

      return pages.map((number, index) => {
        if (number === -1) {
          return <Pagination.Ellipsis key={index} />;
        }
        return (
          <Pagination.Item
            key={index}
            active={number === currentPage}
            onClick={() => paginate(number)}
          >
            {number}
          </Pagination.Item>
        );
      });
    }
  };

  const dropdownRef = useRef(null);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsActive(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // order api data

  const master_data_action_update = async (
    call_id,
    for_status_final,
    reject_text
  ) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();

    fd.append("id_for_delete", call_id);
    fd.append("for_status_final", for_status_final);
    fd.append("reject_order_remark", reject_text);
    await server_post_data(update_customer_order_status, fd)
      .then((Response) => {
        setshowLoaderAdmin(false);
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          closeModal();
          master_data_get(selected.value);
        }
      })
      .catch((error) => {
        console.log("2", error);
        handleError("network");
        setshowLoaderAdmin(false);
      });
  };

  const [showModal, setShowModal] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [showModal3, setShowModal3] = useState(false);
  const [selectedGuestIndex, setSelectedGuestIndex] = useState(null);

  const openModal = (guestName, index, flag) => {
    setSelectedData(guestName);
    setSelectedGuestIndex(flag);
    if (flag === 1) {
      setShowModal(true);
    } else if (flag === 2) {
      setShowModal1(true);
    } else if (flag === 3) {
      setShowModal2(true);
    } else if (flag === 4) {
      setShowModal3(true);
    }
  };

  const confirmVIP = (flag_click, form_name) => {
    let reject_text = "";
    if (flag_click === 2) {
      master_data_action_update(
        SelectedData.primary_id,
        1,
        reject_text,
        flag_click
      );
    } else if (flag_click === 3) {
      let vaild_data = check_vaild_save(form_name);

      if (vaild_data) {
        reject_text = document.getElementById("disc_msg").value;
        master_data_action_update(
          SelectedData.primary_id,
          3,
          reject_text,
          flag_click
        );
      }
    } else if (flag_click === 4) {
      master_data_action_update(SelectedData.primary_id, 2, "", flag_click);
    }
  };

  const closeModal = () => {
    setShowModal(false);
    setShowModal1(false);
    setShowModal2(false);
    setShowModal3(false);
  };

  return (
    <div className="dashboard">
      {showLoaderAdmin && <Loader />}

      <div className="dashboard_container">
        <div className="page_content">
          <div className="page_content_container">
            <Header />
            <div className="pageCntn_head">
              <div className="analyticsHead">
                <div className="analyticFIlter">
                  <div className="col-xl-10">
                    <div className="row m-0">
                      <div className="col-md-4">
                        <div className="dropdownCustom" ref={dropdownRef}>
                          <div
                            onClick={(e) => {
                              setIsActive(!isActive);
                            }}
                            className="dropdownCustom-btn"
                          >
                            {Analytics_text.Period_text}: {selected.label}
                            <span
                              className={
                                isActive
                                  ? "fas fa-caret-up"
                                  : "fas fa-caret-down"
                              }
                            >
                              <img src={dropdown} alt="Shopup Admin" />
                            </span>
                          </div>
                          <div
                            className="dropdownCustom-content"
                            style={{ display: isActive ? "block" : "none" }}
                          >
                            {options_select_drop_feedback.map(function (
                              items,
                              index
                            ) {
                              return (
                                <div
                                  onClick={(e) => {
                                    select_dropdown(items);
                                  }}
                                  className="itemDrop"
                                  key={index}
                                >
                                  {items.label}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                      {customDateActive && (
                        <>
                          <div className="col-md-3 bottomAlgin">
                            <div className="person__calenderFrame_image image_icon_position1 ">
                              <input
                                autoComplete="off"
                                id="startDate"
                                type="date"
                                placeholder="From Date"
                                defaultValue={startDate}
                                onChange={(e) => setStartDate(e.target.value)}
                                className="form-control  input_field_custom4 backcolorWhite"
                                max={computeTodayDate()}
                              />
                            </div>
                          </div>
                          <div className="col-md-3 bottomAlgin">
                            <div className="person__calenderFrame_image image_icon_position1 ">
                              <input
                                autoComplete="off"
                                id="endDate"
                                type="date"
                                placeholder="To Date"
                                defaultValue={endDate}
                                onChange={(e) => setEndDate(e.target.value)}
                                className="form-control  input_field_custom4 backcolorWhite"
                                max={computeTodayDate()}
                              />
                            </div>
                          </div>
                          <div className="col-md-2">
                            <div className="downloadBtnAna">
                              <button onClick={() => search_data()}>
                                {Analytics_text.Search_text}
                              </button>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="downloadBtnAna">
                  <button onClick={handleDownloadButtonClick}>
                    {Analytics_text.download_text}
                  </button>
                </div>
              </div>
            </div>

            <div className="page_body">
              <div className="analytics">
                <div className="analytics_container">
                  <div className="row m-0">
                    <div className="col-md-4">
                      <div className="radilaGraph">
                        <div className="bargrapgh_container m-0">
                          <div className="radilaGraph_container">
                            {!FirstTimeData && (
                              <div className="donut-container">
                                <Chart
                                  options={optionsRadial2}
                                  series={series}
                                  type="donut"
                                  className="donutStyle"
                                />

                                <div className="centerText">
                                  {Analytics_text.overlay_text_netcovers}
                                </div>
                              </div>
                            )}
                          </div>
                          <div className="radilaGraphText">
                            <div className="radilaGraphTextLeft">
                              <h6>{Analytics_text.overlay_text_covers}</h6>
                              <ul>
                                <li>
                                  <div className="legendCOntainer">
                                    <h5>{filteredData.count_carting_web}</h5>
                                  </div>
                                </li>
                                <li hidden>
                                  <div className="legendCOntainer">
                                    <div className="legendColor aggregator1"></div>
                                    <p>
                                      {Analytics_text.overlay_text_aggregator +
                                        "01"}
                                    </p>
                                  </div>
                                </li>
                                <li hidden>
                                  <div className="legendCOntainer">
                                    <div className="legendColor"></div>
                                    <p>
                                      {Analytics_text.overlay_text_aggregator +
                                        "02"}
                                    </p>
                                  </div>
                                </li>
                              </ul>
                            </div>
                            <div className="radilaGraphTextRight">
                              <h6>{Analytics_text.overlay_text_covers_off}</h6>
                              <ul>
                                <li>
                                  <div className="legendCOntainer">
                                    <h5>{filteredData.count_carting_app}</h5>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div className="analyticsCardsContainer">
                        <div className="analyticsCardsRow">
                          <div className="row m-0">
                            <div className="col-md-3 paddingLeft1200">
                              <Link to="/view_orders">
                                <div className="analyticsCard colorCard1">
                                  <p>{Analytics_text.total_order}</p>
                                  <h5>{filteredData.total_order}</h5>
                                  <img
                                    className="infoIconCr"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="bottom"
                                    title="Total number of orders."
                                    src={Info}
                                    alt="info icon"
                                  />
                                </div>
                              </Link>
                            </div>
                            <div className="col-md-3 paddingLeft1200">
                              <Link to="/view_customer">
                                <div className="analyticsCard colorCard1">
                                  <p>{Analytics_text.total_customer}</p>
                                  <h5>{filteredData.no_of_customers}</h5>
                                  <img
                                    className="infoIconCr"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="bottom"
                                    title="Total number of customers."
                                    src={Info}
                                    alt="info icon"
                                  />
                                </div>
                              </Link>
                            </div>

                            <div className="col-md-3 paddingLeft1200">
                              <Link to="/view_product">
                                <div className="analyticsCard colorCard1">
                                  <p>{Analytics_text.total_product}</p>
                                  <h5>{filteredData.no_of_product}</h5>
                                  <img
                                    className="infoIconCr"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="bottom"
                                    title="Total number of products added."
                                    src={Info}
                                    alt="info icon"
                                  />
                                </div>
                              </Link>
                            </div>
                            <div className="col-md-3 paddingLeft1200">
                              <Link to="/view_reports">
                                <div className="analyticsCard colorCard3">
                                  <p>{Analytics_text.total_amount}</p>
                                  <h5>
                                    <span className="px-1">
                                      {filteredData.RUPEES_ICON_LEFT}
                                    </span>
                                    {currentItems.final_amount &&
                                      currentItems.final_amount.toFixed(2)}
                                    <span className="px-1">
                                      {filteredData.RUPEES_ICON_RIGHT}
                                    </span>
                                  </h5>
                                  <img
                                    className="infoIconCr"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="bottom"
                                    title="Total sales amount."
                                    src={Info}
                                    alt="info icon"
                                  />
                                </div>
                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className="analyticsCardsRow">
                          <div className="row m-0">
                            <div className="col-md-3 paddingLeft1200">
                              <Link to="/view_orders">
                                <div className="analyticsCard">
                                  <div className="textInfo">
                                    <p>{Analytics_text.Approval_Pending}</p>
                                    <h5>{filteredData.pending_order}</h5>
                                  </div>
                                  <img
                                    className="infoIconCr"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="bottom"
                                    title="Total number of pending orders."
                                    src={Info}
                                    alt="info icon"
                                  />
                                </div>
                              </Link>
                            </div>
                            <div className="col-md-3 paddingLeft1200">
                              <Link to="/view_orders">
                                <div className="analyticsCard">
                                  <div className="textInfo">
                                    <p>Accept Order</p>
                                    <h5>{filteredData.active_order}</h5>
                                  </div>
                                  <img
                                    className="infoIconCr"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="bottom"
                                    title="Total number of accepted orders."
                                    src={Info}
                                    alt="info icon"
                                  />
                                </div>
                              </Link>
                            </div>
                            <div className="col-md-3 paddingLeft1200">
                              <Link to="/view_orders">
                                <div className="analyticsCard">
                                  <div className="textInfo">
                                    <p>Delivered Order</p>
                                    <h5>{filteredData.completed_order}</h5>
                                  </div>
                                  <img
                                    className="infoIconCr"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="bottom"
                                    title="Total number of delivered orders."
                                    src={Info}
                                    alt="info icon"
                                  />
                                </div>
                              </Link>
                            </div>

                            <div className="col-md-3 paddingLeft1200">
                              <Link to="/view_orders">
                                <div className="analyticsCard">
                                  <div className="textInfo">
                                    <p>{Analytics_text.reject_txt}</p>
                                    <h5>{filteredData.reject_order}</h5>
                                  </div>
                                  <img
                                    className="infoIconCr"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="bottom"
                                    title="Total number of rejected orders."
                                    src={Info}
                                    alt="info icon"
                                  />
                                </div>
                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className="row m-0">
                          <div className="col-md-3 paddingLeft1200">
                            <Link to="/view_product">
                              <div className="analyticsCard">
                                <div className="textInfo">
                                  <p>Pending For Approval</p>
                                  <h5>{filteredData.product_status_0}</h5>
                                </div>
                                <img
                                  className="infoIconCr"
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="bottom"
                                  title="Total number of pending products approvals."
                                  src={Info}
                                  alt="info icon"
                                />
                              </div>
                            </Link>
                          </div>
                          <div className="col-md-3 paddingLeft1200">
                            <Link to="/view_product">
                              <div className="analyticsCard">
                                <div className="textInfo">
                                  <p>Active Product</p>
                                  <h5>{filteredData.product_status_1}</h5>
                                </div>
                                <img
                                  className="infoIconCr"
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="bottom"
                                  title="Total number of active orders."
                                  src={Info}
                                  alt="info icon"
                                />
                              </div>
                            </Link>
                          </div>
                          <div className="col-md-3 paddingLeft1200">
                            <Link to="/view_product">
                              <div className="analyticsCard">
                                <div className="textInfo">
                                  <p>Cancelled Product</p>
                                  <h5>{filteredData.product_status_2}</h5>
                                </div>
                                <img
                                  className="infoIconCr"
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="bottom"
                                  title="Total number of cancelled products approval."
                                  src={Info}
                                  alt="info icon"
                                />
                              </div>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="GuestStatusGraph">
                    <div className="GuestStatusGraphContainer">
                      <div className="GuestStatusGraphHead">
                        <div className="GuestStatusGraphLegends">
                          <div className="legendCOntainer">
                            <div className="legendColor arrivedLegend"></div>
                            <p>{Analytics_text.arrived}</p>
                          </div>
                          <div className="legendCOntainer">
                            <div className="legendColor rescheduleLegend"></div>
                            <p>{Analytics_text.complete_txt}</p>
                          </div>
                          <div className="legendCOntainer">
                            <div className="legendColor cancelledLegend"></div>
                            <p>{Analytics_text.Cancelled_text}</p>
                          </div>
                          <div className="legendCOntainer">
                            <div className="legendColor"></div>
                            <p>{Analytics_text.no_show}</p>
                          </div>
                        </div>
                        <div className="GuestStatusGraphFilter">
                          <div
                            className="downloadImng"
                            data-bs-toggle="tooltip"
                            data-bs-placement="bottom"
                            htmlFor="top"
                            title={`Print Graph`}
                          >
                            <img src={DownloadImg} alt="Shopup Admin" />
                          </div>
                        </div>
                      </div>
                      {!FirstTimeData && (
                        <div>
                          <Chart
                            options={optionsLine}
                            series={seriesLine}
                            type="line"
                            height={350}
                          />
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="feedBackTable">
                    <div className="feedBackTable_container">
                      <h5>{Analytics_text.reservation_text_1}</h5>
                      <div className="tableResponsive">
                        <div className="tableResponsive_container">
                          <table className="table">
                            <thead>
                              <tr>
                                <th scope="col">
                                  <div className="theadStyle imgThead  ">
                                    <span>
                                      {OrdersManagementPageText.Entry_date}
                                    </span>
                                  </div>
                                </th>
                                <th scope="col">
                                  <div className="theadStyle imgThead  ">
                                    <span>
                                      {OrdersManagementPageText.Guest_Image}
                                    </span>
                                  </div>
                                </th>
                                <th scope="col">
                                  <div className="theadStyle imgThead  ">
                                    <span>
                                      {OrdersManagementPageText.Guest_Name}
                                    </span>
                                  </div>
                                </th>
                                <th scope="col">
                                  <div className="theadStyle">
                                    {OrdersManagementPageText.Contact_Details}
                                  </div>
                                </th>
                                <th scope="col">
                                  <div className="theadStyle">Order Type</div>
                                </th>
                                <th scope="col">
                                  <div className="theadStyle">
                                    {OrdersManagementPageText.Email_ID}
                                  </div>
                                </th>

                                <th scope="col">
                                  <div className="theadStyle">
                                    {OrdersManagementPageText.Booking_text}
                                  </div>
                                </th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody className="tboday">
                              {!filteredData.order_data
                                ? []
                                : filteredData.order_data.map(
                                    (option, index) => (
                                      <React.Fragment key={index}>
                                        <tr className="tableRow tbodyStyle">
                                          <td className="tabledata">
                                            <div className="guest_incenter shadowOnlyBottom">
                                              {formatDateStringdot(
                                                option.entry_date
                                              )}{" "}
                                              {TimeformateChangeDtae(
                                                option.entry_date
                                              )}
                                            </div>
                                          </td>
                                          <td className="tabledata">
                                            <div className="guest_incenter shadowOnlyBottom">
                                              <button
                                                type="button"
                                                className={`markVip Mark_Vip $vipMarked`}
                                                onClick={() =>
                                                  openModal(option, index, 1)
                                                }
                                              >
                                                <p> {option.counter_invoice}</p>
                                              </button>
                                            </div>
                                          </td>
                                          <td className="tabledata">
                                            <div className="guest_incenter shadowOnlyBottom">
                                              {option.customer_details.length >
                                                0 &&
                                                `${option.customer_details[0].full_name}`}
                                            </div>
                                          </td>

                                          <td className="tabledata">
                                            <div className="guest_incenter shadowOnlyBottom">
                                              {option.customer_details.length >
                                                0 &&
                                                `${option.customer_details[0].user_moblie_no}`}
                                            </div>
                                          </td>
                                          <td className="tabledata">
                                            <div className="guest_incenter shadowOnlyBottom displaflowroot">
                                              <p>
                                                {option.order_type === 1
                                                  ? "Delivery"
                                                  : "Self Pickup"}
                                              </p>
                                              <p>
                                                {option.order_type === 1 &&
                                                option.delivery_time != null
                                                  ? " Time:-" +
                                                    option.delivery_time
                                                  : ""}
                                              </p>
                                            </div>
                                          </td>
                                          <td className="tabledata">
                                            <div className="guest_incenter shadowOnlyBottom">
                                              {RupeesLeft}{" "}
                                              {option.final_amount.toFixed(2)}{" "}
                                              {RupeesRight}
                                            </div>
                                          </td>
                                          <td className="th2 tabledata">
                                            {option.order_status === 0 ? (
                                              <>
                                                <div className="guest_incenterActions borderRightRadius">
                                                  <button
                                                    type="button"
                                                    className="markVip Mark_Vip vipMarked"
                                                    onClick={() =>
                                                      openModal(
                                                        option,
                                                        index,
                                                        2
                                                      )
                                                    }
                                                  >
                                                    <p>Accept</p>
                                                  </button>
                                                </div>
                                                <div className="guest_incenterActions borderRightRadius">
                                                  <button
                                                    type="button"
                                                    className="markVip Mark_Vip vipMarkedred"
                                                    onClick={() =>
                                                      openModal(
                                                        option,
                                                        index,
                                                        3
                                                      )
                                                    }
                                                  >
                                                    <p>Reject</p>
                                                  </button>
                                                </div>
                                              </>
                                            ) : option.order_status === 1 ? (
                                              <>
                                                <div className="guest_incenterActions borderRightRadius">
                                                  <button
                                                    type="button"
                                                    className="markVip Mark_Vip vipMarked"
                                                    onClick={() =>
                                                      openModal(
                                                        option,
                                                        index,
                                                        4
                                                      )
                                                    }
                                                  >
                                                    <p>Deliver</p>
                                                  </button>
                                                </div>
                                                <div className="guest_incenterActions borderRightRadius">
                                                  <button
                                                    type="button"
                                                    className="markVip Mark_Vip vipMarkedred"
                                                    onClick={() =>
                                                      openModal(
                                                        option,
                                                        index,
                                                        3
                                                      )
                                                    }
                                                  >
                                                    <p>Reject</p>
                                                  </button>
                                                </div>
                                              </>
                                            ) : option.order_status === 2 ? (
                                              <div className="guest_incenterActions borderRightRadius">
                                                <button
                                                  type="button"
                                                  className="markVip Mark_Vip vipMarked"
                                                >
                                                  <p>Delivered</p>
                                                </button>
                                              </div>
                                            ) : option.order_status === 3 ? (
                                              <div className="guest_incenterActions borderRightRadius">
                                                <button
                                                  type="button"
                                                  className="markVip Mark_Vip vipMarkedred"
                                                >
                                                  <p>Reject</p>
                                                </button>
                                              </div>
                                            ) : null}
                                          </td>
                                        </tr>
                                        <tr
                                          key={`spacer-${index}`}
                                          style={{ height: "1rem" }}
                                        ></tr>
                                      </React.Fragment>
                                    )
                                  )}
                              {filteredData.length === 0 && (
                                <tr>
                                  <td
                                    colSpan={8}
                                    className="text_align_center border_1px"
                                  >
                                    No Results Found
                                  </td>{" "}
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <Pagination>
                        <div className="paginationContainer">
                          <div className="nxtBttnTble">
                            {!currentItems && currentPage !== 1 ? (
                              <button
                                className="btn btn-primary"
                                onClick={() =>
                                  setCurrentPage((prev) =>
                                    prev > 1 ? prev - 1 : prev
                                  )
                                }
                              >
                                {Analytics_text.Previous_text}
                              </button>
                            ) : null}
                          </div>
                          <div className="d-flex gap-2">
                            {renderPaginationItems()}
                          </div>
                          {!currentItems && (
                            <div className="nxtBttnTble">
                              <button
                                className="btn btn-primary"
                                disabled={currentPage === totalPageCount}
                                onClick={() =>
                                  setCurrentPage((prev) =>
                                    prev < totalPageCount ? prev + 1 : prev
                                  )
                                }
                              >
                                {Analytics_text.next_text}
                              </button>
                            </div>
                          )}
                        </div>
                      </Pagination>
                    </div>
                  </div>

                  <div className="feedBackTable">
                    <div className="feedBackTable_container">
                      <h5>{Analytics_text.reservation_text_2}</h5>
                      <div className="tableResponsive">
                        <div className="tableResponsive_container">
                          <table
                            id="myTable"
                            ref={tableRef}
                            className="display table"
                          >
                            <thead>
                              <tr>
                                <th scope="col" className="th3">
                                  {Analytics_text.s_no}
                                </th>
                                <th scope="col">{Analytics_text.guest_name}</th>
                                <th scope="col">{Analytics_text.Email_txt}</th>
                                <th scope="col">{Analytics_text.mobile_no}</th>
                                <th scope="col">
                                  {Analytics_text.Reservation_Date_Time}
                                </th>
                              </tr>
                              <tr style={{ height: "25px" }}></tr>
                            </thead>
                            <tbody>
                              {!filteredData.customers_data
                                ? []
                                : filteredData.customers_data.map(
                                    (item, index) => (
                                      <React.Fragment key={index}>
                                        <tr>
                                          <td>
                                            <div className="recentANme">
                                              <p>{index + 1}</p>
                                            </div>
                                          </td>
                                          <td>
                                            <div className="recentContact">
                                              <p>{item.full_name}</p>
                                            </div>
                                          </td>
                                          <td>
                                            <div className="recentContact">
                                              <p>{item.user_email}</p>
                                            </div>
                                          </td>
                                          <td>
                                            <div className="recentContact">
                                              <p>{item.user_moblie_no}</p>
                                            </div>
                                          </td>
                                          <td>
                                            <div className="recentContact">
                                              <p>{item.total_order}</p>
                                            </div>
                                          </td>
                                        </tr>

                                        <tr
                                          style={{
                                            height: "1rem",
                                            boxShadow: "none",
                                          }}
                                        ></tr>
                                      </React.Fragment>
                                    )
                                  )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <Pagination>
                        <div className="paginationContainer">
                          <div className="nxtBttnTble">
                            {!currentItems && currentPage !== 1 ? (
                              <button
                                className="btn btn-primary"
                                onClick={() =>
                                  setCurrentPage((prev) =>
                                    prev > 1 ? prev - 1 : prev
                                  )
                                }
                              >
                                {Analytics_text.Previous_text}
                              </button>
                            ) : null}
                          </div>
                          <div className="d-flex gap-2">
                            {renderPaginationItems()}
                          </div>
                          {!currentItems && (
                            <div className="nxtBttnTble">
                              <button
                                className="btn btn-primary"
                                disabled={currentPage === totalPageCount}
                                onClick={() =>
                                  setCurrentPage((prev) =>
                                    prev < totalPageCount ? prev + 1 : prev
                                  )
                                }
                              >
                                {Analytics_text.next_text}
                              </button>
                            </div>
                          )}
                        </div>
                      </Pagination>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Modal show={showModal2} onHide={closeModal} centered backdrop="static">
          <Modal.Body className="modal_body">
            {" "}
            <form className="createRightsForm" id="createRightsForm">
              <div className="success_img  justify-content-center">
                <div className={`row`}>
                  <div className="inpContainer">
                    <label className="no_prsnl_id d-flex">
                      Reject Remark
                      <span className="colorred">*</span>
                    </label>

                    <div>
                      <input
                        type="text"
                        id="disc_msg"
                        name="disc_msg"
                        maxLength={100}
                        onInput={handleIaphabetnumberChange}
                        className={`  trio_mandatory  input_field_customPadding form-control`}
                        placeholder="Description"
                      />

                      <span className="condition_error"></span>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="logoutYesBtn"
              onClick={() => confirmVIP(selectedGuestIndex, "createRightsForm")}
            >
              Yes
            </Button>
            <Button className="logoutNoBtn" onClick={closeModal}>
              No
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={showModal1} onHide={closeModal} centered backdrop="static">
          <Modal.Body className="modal_body">
            <div className="success_img d-flex justify-content-center">
              {/* ... Modal content goes here ... */}
            </div>

            <p>Are you sure you want to Accept This Order?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="logoutYesBtn"
              onClick={() => confirmVIP(selectedGuestIndex, "")}
            >
              Yes
            </Button>
            <Button className="logoutNoBtn" onClick={closeModal}>
              No
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={showModal3} onHide={closeModal} centered backdrop="static">
          <Modal.Body className="modal_body">
            <div className="success_img d-flex justify-content-center">
              {/* ... Modal content goes here ... */}
            </div>

            <p>Are you sure you want to Deliver This Order?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="logoutYesBtn"
              onClick={() => confirmVIP(selectedGuestIndex, "")}
            >
              Yes
            </Button>
            <Button className="logoutNoBtn" onClick={closeModal}>
              No
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={showModal} onHide={closeModal} centered backdrop="static">
          {SelectedData && SelectedData.order_details && (
            <Modal.Body className="modal_body">
              <div className="success_img d-flex justify-content-center">
                <div className="invoice-box">
                  <table cellPadding="0" cellSpacing="0">
                    <tr className="top_rw">
                      <td colSpan="2">
                        <h2 style={{ marginBottom: "0px" }}>Tax invoice</h2>
                        <span>
                          Number: {SelectedData.counter_invoice} Date:{" "}
                          {formatDateStringdot(SelectedData.entry_date)}
                        </span>
                      </td>
                    </tr>
                    {SelectedData.order_type === 1 && (
                      <tr className="top">
                        <td colSpan="3">
                          <table>
                            <tr>
                              <td>
                                <b>Delivery Address:</b>
                                {SelectedData.full_address}
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    )}

                    <tr className="information">
                      <td colSpan="3">
                        <table cellSpacing="0" cellPadding="2">
                          <tr className="heading">
                            <td>ITEM</td>
                            <td style={{ textAlign: "center" }}>QTY.</td>
                            <td style={{ textAlign: "right" }}>PRICE (INR)</td>
                          </tr>
                          {SelectedData.order_details &&
                            SelectedData.order_details.map((option, index) => (
                              <tr className="item">
                                <td>
                                  {option.product_details[0].product_name}
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  {option.product_qty}
                                </td>
                                <td style={{ textAlign: "right" }}>
                                  {(
                                    option.product_price_single *
                                    option.product_qty
                                  ).toFixed(2)}
                                </td>
                              </tr>
                            ))}
                          <tr
                            className="item"
                            style={{ borderTopWidth: "2px" }}
                          >
                            <td>
                              <b>Sub Total</b>
                            </td>
                            <td style={{ textAlign: "center" }}></td>
                            <td style={{ textAlign: "right" }}>
                              {RupeesLeft}
                              {SelectedData.product_amount.toFixed(2)}{" "}
                              {RupeesRight}
                            </td>
                          </tr>
                          <tr className="item">
                            <td>
                              <b>Delivery Charge</b>
                            </td>
                            <td style={{ textAlign: "center" }}></td>
                            <td style={{ textAlign: "right" }}>
                              {RupeesLeft}
                              {SelectedData.delivery_amount.toFixed(2)}{" "}
                              {RupeesRight}
                            </td>
                          </tr>
                          <tr className="item">
                            <td>
                              <b>Packing Charge</b>
                            </td>
                            <td style={{ textAlign: "center" }}></td>
                            <td style={{ textAlign: "right" }}>
                              {RupeesLeft}
                              {SelectedData.packing_amount.toFixed(2)}{" "}
                              {RupeesRight}
                            </td>
                          </tr>
                          <tr className="item">
                            <td>
                              <b>Promocode Discount</b>
                            </td>
                            <td style={{ textAlign: "center" }}></td>
                            <td style={{ textAlign: "right" }}>
                              - {RupeesLeft}
                              {SelectedData.promocode_amount.toFixed(2)}{" "}
                              {RupeesRight}
                            </td>
                          </tr>
                          <tr
                            className="item"
                            style={{ borderTopWidth: "2px" }}
                          >
                            <td>
                              <b>Grand Total</b>
                            </td>
                            <td style={{ textAlign: "center" }}></td>
                            <td style={{ textAlign: "right" }}>
                              {RupeesLeft}
                              {SelectedData.final_amount.toFixed(2)}{" "}
                              {RupeesRight}
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </Modal.Body>
          )}
          <Modal.Footer>
            <Button className="logoutNoBtn" onClick={closeModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}

export default Analytics;
