import React, { useState } from "react";
import Header from "./Header";
import PrivacyPolicy from "./PrivacyPolicy";
import TermsCondition from "./TermsCondition";
import UpdateAddress from "./UpdateAddress";
import UpdateDeatils from "./UpdateDetails";
import MobileApk from "./MobileApk";
import SocalMedialink from "./SocalMedialink";
import AppLogo from "./AppLogo";

function Setting() {
  const [reservation, setreservation] = useState(false);
  const [sales, setsales] = useState(false);
  const [address, setaddress] = useState(false);
  const [details, setdetails] = useState(false);
  const [apk, setapk] = useState(false);
  const [socal, setsocal] = useState(false);
  const [logo, setlogo] = useState(true);

  const toggleTabs = (Tab) => {
    setlogo(Tab === "Logo");
    setreservation(Tab === "Reservation");
    setsales(Tab === "Sales");
    setaddress(Tab === "Address");
    setdetails(Tab === "Details");
    setapk(Tab === "Apk");
    setsocal(Tab === "Socal");
  };

  return (
    <div className="dashboard">
      <div className="dashboard_container">
        <div className="page_content">
          <div className="page_content_container">
            <Header />
            <div className="pageCntn_head">
              <div className="w-100 pb-1" style={{ overflowX: "auto" }}>
                <div style={{ width: "fit-content" }}>
                  <div className="calendertabs mt-2">
                    <div
                      className="calendertabs_container "
                      style={{ border: "none" }}
                    >
                      <div
                        className={`reportTabs ${
                          logo ? "selectedReports" : ""
                        }`}
                        onClick={() => toggleTabs("Logo")}
                      >
                        <p>App Logo</p>
                      </div>
                      <div
                        className={`reportTabs ${
                          reservation ? "selectedReports" : ""
                        }`}
                        onClick={() => toggleTabs("Reservation")}
                      >
                        <p>Privacy and Policy</p>
                      </div>
                      <div
                        className={`reportTabs ${
                          sales ? "selectedReports" : ""
                        }`}
                        onClick={() => toggleTabs("Sales")}
                      >
                        <p>Terms and Condition</p>
                      </div>
                      <div
                        className={`reportTabs ${
                          address ? "selectedReports" : ""
                        }`}
                        onClick={() => toggleTabs("Address")}
                      >
                        <p>Business Address</p>
                      </div>
                      <div
                        className={`reportTabs ${
                          details ? "selectedReports" : ""
                        }`}
                        onClick={() => toggleTabs("Details")}
                      >
                        <p>Other Deatils</p>
                      </div>
                      <div
                        className={`reportTabs ${apk ? "selectedReports" : ""}`}
                        onClick={() => toggleTabs("Apk")}
                      >
                        <p>Apk Link</p>
                      </div>
                      <div
                        className={`reportTabs ${
                          socal ? "selectedReports" : ""
                        }`}
                        onClick={() => toggleTabs("Socal")}
                      >
                        <p>Socal Media</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="page_body">
              {logo && <AppLogo />}
              {reservation && <PrivacyPolicy />}
              {sales && <TermsCondition />}
              {address && <UpdateAddress />}
              {details && <UpdateDeatils />}
              {apk && <MobileApk />}
              {socal && <SocalMedialink />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Setting;
