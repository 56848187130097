import React, { useState } from "react";

import { loginPagesText } from "../CommonJquery/WebsiteText";
import Loader from "./Loader.js";
import {
  APL_LINK,
  server_post_data,
  login_to_superadmin,
  Website_URL,
  Website_URL_show,
} from "../ServiceConnection/serviceconnection.js";
import {
  check_vaild_save,
  handleNumbersChange,
  handleError,
} from "../CommonJquery/CommonJquery";
import { Link } from "react-router-dom";
import { storeData } from "../LocalConnection/LocalConnection.js";
import { useNavigate } from "react-router-dom";
import BarleysLog from "../assets/logo_shopup.png";
import $ from "jquery";

let login_flag_res = "0";
let data_customer;
let data_customer_FUll_image;
let user_otp_get;
function Login() {
  const navigate = useNavigate();
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);

  const handleSaveChangesdynamic = async (form_data, url_for_save) => {
    let vaild_data = check_vaild_save(form_data);
    if (vaild_data) {
      let vaild = "0";
      let phone_no = $("#phone_no").val();
      let login_otp = $("#user_otp").val();

      if (login_flag_res === "0") {
        if ($.trim(phone_no) === "") {
          vaild = "1";
        } else if (
          $.trim(phone_no).length < 10 ||
          $.trim(phone_no).length > 10
        ) {
          vaild = "1";
        }
      }

      if (login_flag_res === "1") {
        console.log(parseInt(login_otp));
        console.log(parseInt(user_otp_get));
        if (login_otp === "") {
          vaild = "1";
        } else if (parseInt(login_otp) !== parseInt(user_otp_get)) {
          vaild = "1";
        } else {
          storeData("business_id", data_customer.primary_id);
          storeData("vendor_name", data_customer.owner_name);
          storeData("business_name", data_customer.business_name);
          storeData("vendor_mobile_no", data_customer.owner_mobile_no);
          storeData("vendor_email", data_customer.owner_email_id);
          storeData(
            "vendor_image",
            data_customer_FUll_image + data_customer.application_logo
          );
          navigate("/Dashboard");
        }
      }

      if (vaild === "0") {
        setshowLoaderAdmin(true);
        const fd = new FormData();
        fd.append("phone_no", phone_no);
        if (parseInt(login_flag_res) > 0) {
          fd.append("click_type", "1");
        } else {
          fd.append("click_type", login_flag_res);
        }
        await server_post_data(url_for_save, fd)
          .then((Response) => {
            setshowLoaderAdmin(false);
            if (Response.data.error) {
              handleError(Response.data.message);
            } else {
              if (Response.data.message.data_customer.length > 0) {
                data_customer = Response.data.message.data_customer[0];
                data_customer_FUll_image =
                  APL_LINK + Response.data.message.data_admin_image;
                user_otp_get = Response.data.message.otp_send;

                if (login_flag_res === "0") {
                  $("#register_login").html("MATCH OTP");
                  $(".hide_ssection_profile").hide();
                  $(".otp_section").show();
                  $("#user_otp").addClass("trio_mandatory");
                  login_flag_res = "1";
                }
              }
            }
          })
          .catch((error) => {
            setshowLoaderAdmin(false);
          });
      } else {
        if (login_flag_res === "0") {
          handleError("Enter Vaild Mobile No");
        } else if (login_flag_res === "1") {
          handleError("Enter Vaild OTP");
        }
      }
    }
  };

  return (
    <div className="login">
      {showLoaderAdmin && <Loader />}
      <div className="login_container">
        <div className="row h-100 m-0">
          <div className="col-lg-7 p-0">
            <div className="loginLeft">
              <div className="loginText">
                <img src={BarleysLog} alt="ShopupAdmin" />
                <h5>{loginPagesText.tagLineLogin}</h5>

                <Link to={Website_URL}>
                  <button style={{ fontWeight: "500" }}>
                    {Website_URL_show}
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-5 p-0">
            <div className="loginRight">
              <div className="loginFormCol h-100 col-xl-7 col-lg-8 col-md-6 col-11">
                <form id="loginFormData">
                  <div className="loginFormContainer">
                    <h5>{loginPagesText.logingetstarted}</h5>
                    <span className="invalid_data"></span>
                    <div className="loginInputs">
                      <div className="email_image image_icon_position hide_ssection_profile">
                        <input
                          type="text"
                          name="phone_no"
                          id="phone_no"
                          placeholder="Enter Mobile No"
                          className=" trio_mandatory form-control input_field_custom"
                          maxLength={10}
                          minLength={3}
                          onInput={(e) => handleNumbersChange(e)}
                          autoFocus
                          tabIndex={1} // Set tabindex for the email input
                        />
                        <span className="condition_error"></span>
                      </div>
                      <div className="password_image image_icon_position otp_section">
                        <input
                          type="text"
                          name="user_otp"
                          id="user_otp"
                          placeholder="Enter OTP"
                          maxLength={6}
                          minLength={3}
                          className="  form-control input_field_custom"
                          onInput={(e) => handleNumbersChange(e)}
                          tabIndex={2}
                        />
                        <span className="condition_error"></span>
                      </div>
                    </div>
                    <button
                      style={{ color: "white" }}
                      className="loginBtn"
                      id="register_login"
                      type="button"
                      onClick={() =>
                        handleSaveChangesdynamic(
                          "loginFormData",
                          login_to_superadmin
                        )
                      }
                      tabIndex={4} // Set tabindex for the login button
                    >
                      {loginPagesText.login}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
