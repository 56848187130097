import axios from "axios";
import { retrieveData } from "../LocalConnection/LocalConnection.js";
const appauth_key = "barlays@2029";
let APL_LINK = "http://192.168.1.9:8000/";
APL_LINK = "https://backend.shopupapp.in/";
let Website_URL_show = "www.shopupapp.in";
let Website_URL = "https://" + Website_URL_show + "/";
let local_server_link_react = APL_LINK + "api/shopadmin_link/";
let local_server_link_react_admin = APL_LINK + "api/admin_link/";

// API functions
const get_all_faq = local_server_link_react_admin + "get_all_faq";
const get_all_knowledgebase =
  local_server_link_react_admin + "get_all_knowledgebase";

// API functions
const get_all_searchmaster =
  local_server_link_react + "get_all_searchmaster_vendor";
const get_all_guestInformation =
  local_server_link_react + "get_all_guestInformation";
const update_guest_active_status =
  local_server_link_react + "update_guest_active_status";
const get_all_bannerInformation =
  local_server_link_react + "get_all_bannerInformation";
const get_all_business_data = local_server_link_react + "get_all_business_data";
const get_all_customer_for_help =
  local_server_link_react + "get_all_customer_for_help";
const update_customer_help_status =
  local_server_link_react + "update_customer_help_status";
const upload_app_logo_website =
  local_server_link_react + "upload_app_logo_website";
const update_product_price_discount =
  local_server_link_react + "update_product_price_discount";

const save_update_banner = local_server_link_react + "save_update_banner";
const get_promocode_data = local_server_link_react + "get_promocode_data";
const add_promocode_data = local_server_link_react + "add_promocode_data";
const get_notification_data = local_server_link_react + "get_notification_data";
const add_notification_data = local_server_link_react + "add_notification_data";
const get_terms_and_privacy_data =
  local_server_link_react + "get_terms_privacy";
const update_terms_and_privacy_data =
  local_server_link_react + "update_terms_privacy";
const get_all_productInformation =
  local_server_link_react + "get_all_productInformation";
const update_product_active_status =
  local_server_link_react + "update_product_active_status";
const customer_order_data = local_server_link_react + "customer_order_data";
const update_customer_order_status =
  local_server_link_react + "update_customer_order_status";
const search_product_main_database =
  local_server_link_react + "search_product_main_database";
const add_product_webapp = local_server_link_react + "add_product_webapp";

const delete_master_all = local_server_link_react + "delete_master_all";
const update_business_other_field =
  local_server_link_react + "update_business_other_field";
const update_socal_media_link =
  local_server_link_react + "update_socal_media_link";
const update_Apk_file_name = local_server_link_react + "update_Apk_file_name";
const update_business_address =
  local_server_link_react + "update_business_address";
const get_all_guestReport = local_server_link_react + "get_all_guestReport";
const get_all_orderReport = local_server_link_react + "get_all_orderReport";
const get_all_analyticsReservation =
  local_server_link_react + "get_all_analyticsReservation";
const login_to_superadmin = local_server_link_react + "login_vender_shop_admin";
const update_category_active_status =
  local_server_link_react + "update_category_active_status";
const get_all_category = local_server_link_react + "get_all_category";
const get_all_tickets = local_server_link_react_admin + "get_all_tickets";
const get_save_update_tickets = local_server_link_react_admin + "get_save_update_tickets";
const resolve_ticket_by_user = local_server_link_react_admin + "resolve_ticket_by_user";
const get_save_update_tickets_details = local_server_link_react_admin + "get_save_update_tickets_details";

const retrievedAdminId = retrieveData("business_id");
const restaurant_name = retrieveData("business_name");
const server_post_data = async (url_for, form_data) => {
  if (form_data === null) {
    form_data = new FormData();
  }
  form_data.append("business_id", retrievedAdminId);
  form_data.append("business_name", restaurant_name);

  return axios.post(url_for, form_data);
};

export {
  APL_LINK,
  Website_URL,
  Website_URL_show,
  appauth_key,
  server_post_data,
  login_to_superadmin,
  get_all_faq,
  get_all_knowledgebase,
  get_all_analyticsReservation,
  get_all_searchmaster,
  get_all_guestInformation,
  update_guest_active_status,
  get_all_bannerInformation,
  save_update_banner,
  get_promocode_data,
  add_promocode_data,
  get_notification_data,
  add_notification_data,
  customer_order_data,
  update_customer_order_status,
  get_all_productInformation,
  update_product_active_status,
  search_product_main_database,
  add_product_webapp,
  //Shop Admin Links
  delete_master_all,
  get_terms_and_privacy_data,
  update_terms_and_privacy_data,
  get_all_business_data,
  update_business_other_field,
  update_socal_media_link,
  update_Apk_file_name,
  update_business_address,
  get_all_customer_for_help,
  update_customer_help_status,
  get_all_guestReport,
  get_all_orderReport,
  upload_app_logo_website,
  update_product_price_discount,
  update_category_active_status,
  get_all_category,
  get_all_tickets,
  get_save_update_tickets,
  resolve_ticket_by_user,
  get_save_update_tickets_details,
  //Shop Admin Links
};
