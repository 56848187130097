import React, { useState, useEffect } from "react";
import Header from "./Header.js";
import Loader from "./Loader.js";
import GreyArrow from "../assets/greyLeftAr.png";
import {
  CreateStaffRightText,
  EditStaffPage,
} from "../CommonJquery/WebsiteText.js";
import {
  check_vaild_save,
  combiled_form_data,
  handleSuccessSession,
  handleError,
  empty_form,
  handleNumbersChange,
  computeTodayDate,
  computeFutureDate,
  handleIaphabetnumberChange,
} from "../CommonJquery/CommonJquery.js";
import {
  server_post_data,
  add_promocode_data,
  get_promocode_data,
} from "../ServiceConnection/serviceconnection.js";
import { Link, useLocation } from "react-router-dom";

function Add_Promocode() {
  const location = useLocation();
  const currentUrl = location.pathname.substring(1);
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [editorDataMainID, setEditorDatMainID] = useState("0");
  const [editStaffData, seteditStaffData] = useState([]);
  const [discountamount, setdiscountamount] = useState(true);

  const handleSelect = (event) => {
    if (Number(event.target.value) === 0) {
      setdiscountamount(true);
    } else {
      setdiscountamount(false);
    }
    document.getElementById("min_amount").value = 0;
    document.getElementById("max_amount").value = 0;
  };

  const handleSaveChangesdynamic = async (form_data, url_for_save) => {
    let vaild_data = check_vaild_save(form_data);

    if (vaild_data) {
      setshowLoaderAdmin(true);
      let fd_from = combiled_form_data(form_data, null);
      fd_from.append("main_id", editorDataMainID);
      await server_post_data(url_for_save, fd_from)
        .then((Response) => {
          setshowLoaderAdmin(false);
          if (Response.data.error) {
            handleError(Response.data.message);
          } else {
            master_data_get("", "", "1", "0");
            handleSuccessSession(Response.data.message, "/view_promocode");
            empty_form(form_data);
          }
        })
        .catch((error) => {
          console.log(error);
          setshowLoaderAdmin(false);
        });
    }
  };

  useEffect(() => {
    master_data_get();
  }, []);

  const master_data_get = async () => {
    const url = currentUrl;
    const parts = url.split("/");
    let call_id = "0";
    if (parts.length !== 1) {
      call_id = parts[1];
    }
    if (call_id !== "0") {
      setshowLoaderAdmin(true);
      const fd = new FormData();
      fd.append("call_id", call_id);
      fd.append("flag", "3");
      await server_post_data(get_promocode_data, fd)
        .then((Response) => {
          if (Response.data.error) {
            handleError(Response.data.message);
          } else {
            if (Response.data.message.data_businesspromocode.length > 0) {
              setEditorDatMainID(
                Response.data.message.data_businesspromocode[0].primary_id
              );
              seteditStaffData(Response.data.message.data_businesspromocode[0]);
              document.getElementById("min_amount").value =
                Response.data.message.data_businesspromocode[0].min_amount;
              document.getElementById("max_amount").value =
                Response.data.message.data_businesspromocode[0].max_amount;
              document.getElementById("discount_amount").value =
                Response.data.message.data_businesspromocode[0].discount_amount;
            }
          }
          setshowLoaderAdmin(false);
        })
        .catch((error) => {
          handleError("network");
          setshowLoaderAdmin(false);
        });
    }
  };

  const options_search = [
    { value: 0, label: "Discount" },
    { value: 1, label: "Amount" },
  ];

  return (
    <div className="dashboard">
      {showLoaderAdmin && <Loader />}

      <div className="dashboard_container">
        <div className="page_content">
          <div className="page_content_container">
            <Header />
            <div className="pageCntn_head container-lg">
              <div className="flexCOntauiner">
                <Link to="/view_promocode">
                  <div className="pageNameDiv">
                    <p>Promocode Management</p>
                    <img src={GreyArrow} alt="Shopup Admin" />
                  </div>
                </Link>
                <div className="pageNameDiv px-0">
                  <p>
                    <p>
                      {currentUrl == "add_promocode"
                        ? "Add Promocode"
                        : "Edit Promocode"}
                    </p>
                  </p>
                  <img src={GreyArrow} alt="Shopup Admin" />
                </div>
              </div>
            </div>

            <div className="page_body">
              <div className="create_staffRights container-lg">
                <form className="createRightsForm" id="createRightsForm">
                  <div className="row m-0">
                    <div className="personalForm">
                      <div className="staffForm_container">
                        <div className="row m-0">
                          <div className="col-md-3">
                            <div className="inpContainer">
                              <div className="faqDropdown">
                                <div className="inpContainer">
                                  <label className="no_prsnl_id">
                                    Discount/Amount
                                    <span className="colorred">*</span>
                                  </label>
                                  <div>
                                    <select
                                      id="promocode_type"
                                      name="promocode_type"
                                      className={`trio_mandatory form-control input_field_custom2 input_field_customPadding `}
                                      onChange={handleSelect}
                                    >
                                      {options_search.map((option) => (
                                        <option
                                          key={option.value}
                                          value={option.value}
                                          selected={
                                            editStaffData.promocode_type ===
                                            option.value
                                              ? true
                                              : false
                                          }
                                        >
                                          {option.label}
                                        </option>
                                      ))}
                                    </select>
                                    <span className="condition_error"></span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className={`col-md-3`}>
                            <div className="inpContainer">
                              <label className="no_prsnl_id">
                                Promocode Name
                                <span className="colorred">*</span>
                              </label>
                              <div>
                                <input
                                  type="text"
                                  id="promo_code"
                                  name="promo_code"
                                  className={`  trio_mandatory  input_field_customPadding form-control`}
                                  placeholder="Promocode Name"
                                  defaultValue={editStaffData.promo_code || ""}
                                />

                                <span className="condition_error"></span>
                              </div>
                            </div>
                          </div>
                          <div className={`col-md-3`}>
                            <div className="inpContainer">
                              <label className="no_prsnl_id">
                                Promocode{" "}
                                {discountamount ? "Discount" : "Amount"}
                                <span className="colorred">*</span>
                              </label>

                              <div>
                                <input
                                  type="text"
                                  id="discount_amount"
                                  name="discount_amount"
                                  maxLength={discountamount ? "2" : "5"}
                                  onInput={handleNumbersChange}
                                  className={`  trio_mandatory  input_field_customPadding form-control `}
                                  placeholder={`Promocode ${
                                    discountamount ? "Discount" : "Amount"
                                  }`}
                                  defaultValue={
                                    editStaffData.discount_amount || "0"
                                  }
                                />

                                <span className="condition_error"></span>
                              </div>
                            </div>
                          </div>
                          <div className={`col-md-3`} hidden>
                            <div className="inpContainer">
                              <label className="no_prsnl_id">
                                User Limit
                                <span className="colorred">*</span>
                              </label>

                              <div>
                                <input
                                  type="text"
                                  id="coupon_limit"
                                  name="coupon_limit"
                                  maxLength={3}
                                  onInput={handleNumbersChange}
                                  className={`  trio_mandatory  input_field_customPadding form-control`}
                                  placeholder="User Limit"
                                  defaultValue={
                                    editStaffData.coupon_limit || "0"
                                  }
                                />

                                <span className="condition_error"></span>
                              </div>
                            </div>
                          </div>
                          <div className={`col-md-3`} hidden>
                            <div className="inpContainer">
                              <label className="no_prsnl_id">
                                Per User Limit
                                <span className="colorred">*</span>
                              </label>

                              <div>
                                <input
                                  type="text"
                                  id="coupon_user_limit"
                                  name="coupon_user_limit"
                                  maxLength={3}
                                  onInput={handleNumbersChange}
                                  className={`  trio_mandatory  input_field_customPadding form-control`}
                                  placeholder="Per User Limit"
                                  defaultValue={
                                    editStaffData.coupon_user_limit || "0"
                                  }
                                />

                                <span className="condition_error"></span>
                              </div>
                            </div>
                          </div>
                          <div
                            className={`col-md-3 ${
                              discountamount ? "" : "hidden"
                            }`}
                          >
                            <div className="inpContainer">
                              <label className="no_prsnl_id">
                                Min Amount
                                <span className="colorred">*</span>
                              </label>

                              <div>
                                <input
                                  type="text"
                                  id="min_amount"
                                  name="min_amount"
                                  maxLength={3}
                                  onInput={handleNumbersChange}
                                  className={`  trio_mandatory  input_field_customPadding form-control`}
                                  placeholder="Min Amount"
                                  defaultValue={editStaffData.min_amount || "0"}
                                />

                                <span className="condition_error"></span>
                              </div>
                            </div>
                          </div>
                          <div
                            className={`col-md-3 ${
                              discountamount ? "" : "hidden"
                            }`}
                          >
                            <div className="inpContainer">
                              <label className="no_prsnl_id">
                                Max Amount
                                <span className="colorred">*</span>
                              </label>

                              <div>
                                <input
                                  type="text"
                                  id="max_amount"
                                  name="max_amount"
                                  maxLength={3}
                                  onInput={handleNumbersChange}
                                  className={`  trio_mandatory  input_field_customPadding form-control`}
                                  placeholder="Max Amount"
                                  defaultValue={editStaffData.max_amount || "0"}
                                />

                                <span className="condition_error"></span>
                              </div>
                            </div>
                          </div>
                          <div className={`col-md-3`}>
                            <div className="inpContainer">
                              <label className="no_prsnl_id">
                                Available From
                                <span className="colorred">*</span>
                              </label>

                              <div className="person__calenderFrame_image image_icon_position2 ">
                                <input
                                  type="date"
                                  id="avail_from"
                                  name="avail_from"
                                  min={computeTodayDate()}
                                  max={computeFutureDate()}
                                  className={`  trio_mandatory  input_field_customPadding form-control input_field_custom1`}
                                  placeholder="Available From"
                                  defaultValue={
                                    editStaffData.avail_from ||
                                    computeTodayDate()
                                  }
                                />

                                <span className="condition_error"></span>
                              </div>
                            </div>
                          </div>
                          <div className={`col-md-3`}>
                            <div className="inpContainer">
                              <label className="no_prsnl_id">
                                Available To
                                <span className="colorred">*</span>
                              </label>

                              <div className="person__calenderFrame_image image_icon_position2 ">
                                <input
                                  type="date"
                                  id="expiration"
                                  name="expiration"
                                  min={computeTodayDate()}
                                  max={computeFutureDate()}
                                  className={`  trio_mandatory  input_field_customPadding form-control input_field_custom1`}
                                  placeholder="Available To"
                                  defaultValue={
                                    editStaffData.expiration ||
                                    computeTodayDate()
                                  }
                                />

                                <span className="condition_error"></span>
                              </div>
                            </div>
                          </div>
                          <div className={`col-md-9`}>
                            <div className="inpContainer">
                              <label className="no_prsnl_id">
                                Description
                                <span className="colorred">*</span>
                              </label>

                              <div>
                                <input
                                  type="text"
                                  id="disc_msg"
                                  name="disc_msg"
                                  maxLength={100}
                                  onInput={handleIaphabetnumberChange}
                                  className={`  trio_mandatory  input_field_customPadding form-control`}
                                  placeholder="Description"
                                  defaultValue={editStaffData.disc_msg || ""}
                                />

                                <span className="condition_error"></span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="createRightBtnDiv">
                          <button
                            className="btn btn-primary Create_Right_Btn btnSave"
                            type="button"
                            onClick={() =>
                              handleSaveChangesdynamic(
                                "createRightsForm",
                                add_promocode_data
                              )
                            }
                          >
                            {location.pathname.includes("/edit_promocode")
                              ? EditStaffPage.save_text
                              : CreateStaffRightText.Create_text}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Add_Promocode;
