import React, { useState, useEffect } from "react";
import Header from "./Header.js";
import Loader from "./Loader.js";
import GreyArrow from "../assets/greyLeftAr.png";
import FilterIcon from "../assets/filterIcon.png";
import Search from "../assets/search.png";
import { Modal, Button } from "react-bootstrap";
import {
  options_search_order,
  OrdersManagementPageText,
} from "../CommonJquery/WebsiteText.js";
import {
  server_post_data,
  customer_order_data,
  update_customer_order_status,
  APL_LINK,
} from "../ServiceConnection/serviceconnection.js";
import {
  handleError,
  computeTodayDate,
  handleIaphabetnumberChange,
  handleNumbersChange,
  computeplussevendays,
  formatDateStringdot,
  check_vaild_save,
  TimeformateChangeDtae,
} from "../CommonJquery/CommonJquery.js";
function OrderMngmt() {
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [editStaffData, seteditStaffData] = useState([]);
  // Set initial state using useState hook
  const [startDate, setStartDate] = useState(computeplussevendays());
  const [endDate, setEndDate] = useState(computeTodayDate());
  const [SelectedData, setSelectedData] = useState([]);
  const [searchfilter, setSearchFilter] = useState("");
  const [filteredData, setfilteredData] = useState([]);
  const [flagData, setflagData] = useState("1");
  const [selectedValue, setSelectedValue] = useState(
    options_search_order[0].value
  ); // State to hold the selected value
  const [StaffImageLinkData, setsStaffImageLinkData] = useState("");
  const [RupeesLeft, setsRupeesLeft] = useState("");
  const [RupeesRight, setsRupeesRight] = useState("");
  const handleSelect = (event) => {
    setSelectedValue(event.target.value); // Update the selected value when an option is selected
  };

  useEffect(() => {
    const flag = "1";
    let call_id = "0";
    master_data_get(startDate, endDate, flag, call_id);
  }, []);

  const search_data = () => {
    const flag = "2";
    const call_id = "0";
    master_data_get(startDate, endDate, flag, call_id);
  };

  const master_data_get = async (start_date, end_date, flag, call_id) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    setflagData(flag);
    fd.append("start_date", start_date);
    fd.append("end_date", end_date);
    fd.append("flag", flag);
    fd.append("call_id", call_id);
    fd.append("search_option", selectedValue);
    fd.append("search_data", document.getElementById("search_data").value);
    await server_post_data(customer_order_data, fd)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          setsStaffImageLinkData(
            APL_LINK + Response.data.message.product_image_route
          );
          seteditStaffData(Response.data.message.data_customerorder);
          setfilteredData(Response.data.message.data_customerorder);
          setsRupeesLeft(Response.data.message.rupees_left);
          setsRupeesRight(Response.data.message.rupess_right);
        }

        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        console.log(error);
        handleError("network");
        setshowLoaderAdmin(false);
      });
  };

  const master_data_action_update = async (
    call_id,
    for_status_final,
    reject_text
  ) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();

    fd.append("id_for_delete", call_id);
    fd.append("for_status_final", for_status_final);
    fd.append("reject_order_remark", reject_text);
    await server_post_data(update_customer_order_status, fd)
      .then((Response) => {
        setshowLoaderAdmin(false);
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          closeModal();
          master_data_get(startDate, endDate, flagData, "0");
        }
      })
      .catch((error) => {
        handleError("network");
        setshowLoaderAdmin(false);
      });
  };

  const handleSearch = (event) => {
    const searchValue = event.target.value;
    setSearchFilter(searchValue);

    // Filter table data based on search value
    const filteredDatashow = editStaffData.filter((row) => {
      return Object.values(row).some((value) => {
        if (value !== null && value !== undefined) {
          // Add null check here
          return (
            typeof value === "string"
              ? value.toLowerCase()
              : value.toString().toLowerCase()
          ).includes(searchValue.toLowerCase());
        }
        return false;
      });
    });

    setfilteredData(filteredDatashow);
  };
  const [showModal, setShowModal] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [showModal3, setShowModal3] = useState(false);
  const [selectedGuestIndex, setSelectedGuestIndex] = useState(null);

  const openModal = (guestName, index, flag) => {
    setSelectedData(guestName);
    setSelectedGuestIndex(flag);
    if (flag === 1) {
      setShowModal(true);
    } else if (flag === 2) {
      setShowModal1(true);
    } else if (flag === 3) {
      setShowModal2(true);
    } else if (flag === 4) {
      setShowModal3(true);
    }
  };

  const confirmVIP = (flag_click, form_name) => {
    let reject_text = "";
    if (flag_click === 2) {
      master_data_action_update(
        SelectedData.primary_id,
        1,
        reject_text,
        flag_click
      );
    } else if (flag_click === 3) {
      let vaild_data = check_vaild_save(form_name);

      if (vaild_data) {
        reject_text = document.getElementById("disc_msg").value;
        master_data_action_update(
          SelectedData.primary_id,
          3,
          reject_text,
          flag_click
        );
      }
    } else if (flag_click === 4) {
      master_data_action_update(SelectedData.primary_id, 2, "", flag_click);
    }
  };

  const closeModal = () => {
    setShowModal(false);
    setShowModal1(false);
    setShowModal2(false);
    setShowModal3(false);
  };

  console.log(SelectedData);

  return (
    <div className="dashboard">
      {showLoaderAdmin && <Loader />}

      <div className="dashboard_container">
        <div className="page_content">
          <div className="page_content_container">
            <Header />
            <div className="pageCntn_head">
              <div className="pageNameDiv">
                <p>{OrdersManagementPageText.Guest_Management}</p>
                <img src={GreyArrow} alt="Shopup Admin" />
              </div>
            </div>

            <div className="page_body">
              <div className="viewStaff">
                <div className="viewStaff_head">
                  <div className="row m-0">
                    <div className="col-lg-2 col-sm-4 bottomAlgin">
                      <label className="labelView">
                        {OrdersManagementPageText.Start_Date}
                      </label>
                      <div className="person__calenderFrame_image image_icon_position1 ">
                        <input
                          id="startDate"
                          type="date"
                          placeholder={OrdersManagementPageText.D_O_B}
                          className="form-control  input_field_custom4"
                          defaultValue={startDate}
                          onChange={(e) => setStartDate(e.target.value)}
                          max={computeTodayDate()}
                        />
                      </div>
                    </div>
                    <div className="col-lg-2 col-sm-4 bottomAlgin">
                      <label className="labelView">
                        {OrdersManagementPageText.End_Date}
                      </label>
                      <div className="person__calenderFrame_image image_icon_position1 ">
                        <input
                          id="endDate"
                          type="date"
                          placeholder={OrdersManagementPageText.D_O_B}
                          className="form-control  input_field_custom4"
                          defaultValue={endDate}
                          onChange={(e) => setEndDate(e.target.value)}
                          max={computeTodayDate()}
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-4 bottomAlgin">
                      <div className="inputDiv2">
                        <label className="labelPointer" htmlFor="selectFilter">
                          <img src={FilterIcon} alt="Shopup Admin" />
                        </label>
                        <select id="selectFilter" onChange={handleSelect}>
                          {options_search_order.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-5 col-sm-8 bottomAlgin">
                      <div className="inputDiv2">
                        <img src={Search} alt="Shopup Admin" />
                        <input
                          type="text"
                          id="search_data"
                          onInput={(e) => {
                            handleNumbersChange(e);
                          }}
                          placeholder={OrdersManagementPageText.Search_Guest}
                        />
                        <button
                          type="button"
                          className="btnSearch"
                          onClick={() => search_data()}
                        >
                          {OrdersManagementPageText.Search_text}
                        </button>
                      </div>
                    </div>

                    <div
                      className="col-xl-3 col-md-4 mt-3"
                      style={{
                        marginLeft: "auto",
                        marginRight: "0",
                        marginTop: "10",
                      }}
                    >
                      <div className="inputDiv2">
                        <label className="labelPointer" htmlFor="selectFilter">
                          <img src={FilterIcon} alt="Shopup Admin" />
                        </label>
                        <input
                          type="text"
                          placeholder={OrdersManagementPageText.Guest_Filter}
                          value={searchfilter}
                          onInput={handleIaphabetnumberChange}
                          onChange={handleSearch}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="viewGuest_table ">
                  <div className="viewGuest_table_container ">
                    <div className="row m-0">
                      <div className="col-md-12">
                        <table className="table">
                          <thead>
                            <tr>
                              <th scope="col">
                                <div className="theadStyle imgThead  ">
                                  <span>
                                    {OrdersManagementPageText.Entry_date}
                                  </span>
                                </div>
                              </th>
                              <th scope="col">
                                <div className="theadStyle imgThead  ">
                                  <span>
                                    {OrdersManagementPageText.Guest_Image}
                                  </span>
                                </div>
                              </th>
                              <th scope="col">
                                <div className="theadStyle imgThead  ">
                                  <span>
                                    {OrdersManagementPageText.Guest_Name}
                                  </span>
                                </div>
                              </th>
                              <th scope="col">
                                <div className="theadStyle">
                                  {OrdersManagementPageText.Contact_Details}
                                </div>
                              </th>
                              <th scope="col">
                                <div className="theadStyle">Order Type</div>
                              </th>
                              <th scope="col">
                                <div className="theadStyle">
                                  {OrdersManagementPageText.Email_ID}
                                </div>
                              </th>

                              <th scope="col">
                                <div className="theadStyle">
                                  {OrdersManagementPageText.Booking_text}
                                </div>
                              </th>
                              <th scope="col">
                                <div className="theadStyle">
                                  {OrdersManagementPageText.Booking_Details}
                                </div>
                              </th>
                            </tr>
                          </thead>
                          <tbody className="tboday">
                            {filteredData.map((option, index) => (
                              <React.Fragment key={index}>
                                <tr className="tableRow tbodyStyle">
                                  <td className="tabledata">
                                    <div className="guest_incenter shadowOnlyBottom">
                                      {formatDateStringdot(option.entry_date)}{" "}
                                      {TimeformateChangeDtae(option.entry_date)}
                                    </div>
                                  </td>
                                  <td className="tabledata">
                                    <div className="guest_incenter shadowOnlyBottom">
                                      <p>#{option.counter_invoice}</p>
                                    </div>
                                  </td>
                                  <td className="tabledata">
                                    <div className="guest_incenter shadowOnlyBottom">
                                      {option.customer_details.length > 0 &&
                                        `${option.customer_details[0].full_name}`}
                                    </div>
                                  </td>

                                  <td className="tabledata">
                                    <div className="guest_incenter shadowOnlyBottom">
                                      {option.customer_details.length > 0 &&
                                        `${option.customer_details[0].user_moblie_no}`}
                                    </div>
                                  </td>
                                  <td className="tabledata">
                                    <div className="guest_incenter shadowOnlyBottom displaflowroot">
                                      <p>
                                        {option.order_type === 1
                                          ? "Delivery"
                                          : "Self Pickup"}
                                      </p>
                                      <p>
                                        {option.order_type === 1 &&
                                        option.delivery_time != null
                                          ? " Time:-" + option.delivery_time
                                          : ""}
                                      </p>
                                    </div>
                                  </td>
                                  <td className="tabledata">
                                    <div className="guest_incenter shadowOnlyBottom">
                                      {RupeesLeft}{" "}
                                      {option.final_amount.toFixed(2)}{" "}
                                      {RupeesRight}
                                    </div>
                                  </td>
                                  <td className="th2 tabledata">
                                    {option.order_status === 0 ? (
                                      <>
                                        <div className="guest_incenterActions borderRightRadius">
                                          <button
                                            type="button"
                                            className="markVip Mark_Vip m-0 vipMarked"
                                            onClick={() =>
                                              openModal(option, index, 2)
                                            }
                                          >
                                            <p>Accept</p>
                                          </button>
                                        </div>
                                        <div className="guest_incenterActions borderRightRadius">
                                          <button
                                            type="button"
                                            className="markVip Mark_Vip m-0 vipMarkedred"
                                            onClick={() =>
                                              openModal(option, index, 3)
                                            }
                                          >
                                            <p>Reject</p>
                                          </button>
                                        </div>
                                      </>
                                    ) : option.order_status === 1 ? (
                                      <>
                                        <div className="guest_incenterActions borderRightRadius">
                                          <button
                                            type="button"
                                            className="markVip Mark_Vip m-0 vipMarked"
                                            onClick={() =>
                                              openModal(option, index, 4)
                                            }
                                          >
                                            <p>Deliver</p>
                                          </button>
                                        </div>
                                        <div className="guest_incenterActions borderRightRadius">
                                          <button
                                            type="button"
                                            className="markVip Mark_Vip m-0 vipMarkedred"
                                            onClick={() =>
                                              openModal(option, index, 3)
                                            }
                                          >
                                            <p>Reject</p>
                                          </button>
                                        </div>
                                      </>
                                    ) : option.order_status === 2 ? (
                                      <div className="guest_incenterActions borderRightRadius">
                                        <button
                                          type="button"
                                          className="markVip Mark_Vip m-0 vipMarked"
                                        >
                                          <p>Delivered</p>
                                        </button>
                                      </div>
                                    ) : option.order_status === 3 ? (
                                      <div className="guest_incenterActions borderRightRadius">
                                        <button
                                          type="button"
                                          className="markVip Mark_Vip m-0 vipMarkedred"
                                        >
                                          <p>Rejected</p>
                                        </button>
                                      </div>
                                    ) : null}
                                  </td>
                                  <td className="tabledata">
                                    <div className="guest_incenter shadowOnlyBottom">
                                      <button
                                        type="button"
                                        className={`markVip Mark_Vip m-0`}
                                        onClick={() =>
                                          openModal(option, index, 1)
                                        }
                                      >
                                        <p>See Details</p>
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                                <tr
                                  key={`spacer-${index}`}
                                  style={{ height: "1rem" }}
                                ></tr>
                              </React.Fragment>
                            ))}
                            {filteredData.length === 0 && (
                              <tr>
                                <td
                                  colSpan={8}
                                  className="text_align_center border_1px"
                                >
                                  No Results Found
                                </td>{" "}
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showModal2} onHide={closeModal} centered backdrop="static">
        <Modal.Body className="modal_body">
          {" "}
          <form className="createRightsForm" id="createRightsForm">
            <div className="success_img d-flex justify-content-center">
              <div className={`row`}>
                <div className="inpContainer">
                  <label className="no_prsnl_id">
                    Reject Remark
                    <span className="colorred">*</span>
                  </label>

                  <div>
                    <input
                      type="text"
                      id="disc_msg"
                      name="disc_msg"
                      maxLength={100}
                      onInput={handleIaphabetnumberChange}
                      className={`  trio_mandatory  input_field_customPadding form-control`}
                      placeholder="Description"
                      defaultValue={editStaffData.reject_order_remark || ""}
                    />

                    <span className="condition_error"></span>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="logoutYesBtn"
            onClick={() => confirmVIP(selectedGuestIndex, "createRightsForm")}
          >
            Yes
          </Button>
          <Button className="logoutNoBtn" onClick={closeModal}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showModal1} onHide={closeModal} centered backdrop="static">
        <Modal.Body className="modal_body">
          <div className="success_img d-flex justify-content-center">
            {/* ... Modal content goes here ... */}
          </div>

          <p>Are you sure you want to Accept This Order?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="logoutYesBtn"
            onClick={() => confirmVIP(selectedGuestIndex, "")}
          >
            Yes
          </Button>
          <Button className="logoutNoBtn" onClick={closeModal}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showModal3} onHide={closeModal} centered backdrop="static">
        <Modal.Body className="modal_body">
          <div className="success_img d-flex justify-content-center">
            {/* ... Modal content goes here ... */}
          </div>

          <p>Are you sure you want to Deliver This Order?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="logoutYesBtn"
            onClick={() => confirmVIP(selectedGuestIndex, "")}
          >
            Yes
          </Button>
          <Button className="logoutNoBtn" onClick={closeModal}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showModal} onHide={closeModal} centered backdrop="static">
        {SelectedData && SelectedData.order_details && (
          <Modal.Body className="modal_body">
            <div className="success_img d-flex justify-content-center">
              <div className="invoice-box">
                <table cellPadding="0" cellSpacing="0">
                  <tr className="top_rw">
                    <td colSpan="2">
                      <h2 style={{ marginBottom: "0px" }}>Tax invoice</h2>
                      <span>
                        Number: {SelectedData.counter_invoice} Date:{" "}
                        {formatDateStringdot(SelectedData.entry_date)}
                      </span>
                    </td>
                  </tr>
                  {SelectedData.order_type === 1 && (
                    <tr className="top">
                      <td colSpan="3">
                        <table>
                          <tr>
                            <td>
                              <b>Delivery Address:</b>
                              {SelectedData.full_address}
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  )}

                  <tr className="information">
                    <td colSpan="3">
                      <table cellSpacing="0" cellPadding="2">
                        <tr className="heading">
                          <td>ITEM</td>
                          <td style={{ textAlign: "center" }}>QTY.</td>
                          <td style={{ textAlign: "right" }}>PRICE (INR)</td>
                        </tr>
                        {SelectedData.order_details &&
                          SelectedData.order_details.map((option, index) => (
                            <tr className="item">
                              <td>{option.product_details[0].product_name}</td>
                              <td style={{ textAlign: "center" }}>
                                {option.product_qty}
                              </td>
                              <td style={{ textAlign: "right" }}>
                                {(
                                  option.product_price_single *
                                  option.product_qty
                                ).toFixed(2)}
                              </td>
                            </tr>
                          ))}
                        <tr className="item" style={{ borderTopWidth: "2px" }}>
                          <td>
                            <b>Sub Total</b>
                          </td>
                          <td style={{ textAlign: "center" }}></td>
                          <td style={{ textAlign: "right" }}>
                            {RupeesLeft}
                            {SelectedData.product_amount.toFixed(2)}{" "}
                            {RupeesRight}
                          </td>
                        </tr>
                        <tr className="item">
                          <td>
                            <b>Delivery Charge</b>
                          </td>
                          <td style={{ textAlign: "center" }}></td>
                          <td style={{ textAlign: "right" }}>
                            {RupeesLeft}
                            {SelectedData.delivery_amount.toFixed(2)}{" "}
                            {RupeesRight}
                          </td>
                        </tr>
                        <tr className="item">
                          <td>
                            <b>Packing Charge</b>
                          </td>
                          <td style={{ textAlign: "center" }}></td>
                          <td style={{ textAlign: "right" }}>
                            {RupeesLeft}
                            {SelectedData.packing_amount.toFixed(2)}{" "}
                            {RupeesRight}
                          </td>
                        </tr>
                        <tr className="item">
                          <td>
                            <b>Promocode Discount</b>
                          </td>
                          <td style={{ textAlign: "center" }}></td>
                          <td style={{ textAlign: "right" }}>
                            - {RupeesLeft}
                            {SelectedData.promocode_amount.toFixed(2)}{" "}
                            {RupeesRight}
                          </td>
                        </tr>
                        <tr className="item" style={{ borderTopWidth: "2px" }}>
                          <td>
                            <b>Grand Total</b>
                          </td>
                          <td style={{ textAlign: "center" }}></td>
                          <td style={{ textAlign: "right" }}>
                            {RupeesLeft}
                            {SelectedData.final_amount.toFixed(2)} {RupeesRight}
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </Modal.Body>
        )}
        <Modal.Footer>
          <Button className="logoutNoBtn" onClick={closeModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default OrderMngmt;
